/**
 * .common-panel-textinfoboxes-wrapper.
 */

.common-panel-textinfoboxes-wrapper {

    /**
     * Overflow.
     */

    overflow: hidden;

    /**
     * .common-panel-textinfoboxes-container.
     */

    .common-panel-textinfoboxes-container {
     
        /**
         * .row-footnote.
         */

        .row-footnote {

            /**
             * p.s
             */

            p {

                /**
                 * Transform.
                 */

                transform: translateY(-10px);

                /**
                 * Font related.
                 */

                font-size: 14px;
                line-height: 18px;
                text-align: center;

            }

        }

        /**
         * .col-box.
         */

        .col-box {

            /**
             * Margin.
             */

            margin-bottom: 30px;

            /**
             * .inset.
             */

            .inset {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;
                align-items: center;
                justify-content: center;

                /**
                 * Padding.
                 */

                padding: 30px;
                
                /**
                 * Font related.
                 */

                text-align: center;

                /**
                 * Sizing.
                 */

                height: 100%;

                /**
                 * 
                 */

                min-height: 280px;

            }

            /**
             * .heading.
             */

            .heading {

                /**
                 * Font related.
                 */

                font-family: AdventuresUnlimitedScriptRegular;
                font-size: 80px;
                line-height: 40px;
                
                /**
                 * Margin.
                 */

                margin-bottom: 30px;

                /**
                 * &.heading-size-large.
                 */

                &.heading-size-large {

                    /**
                     * Font related.
                     */

                    font-size: 130px;
                    line-height: 50px;

                }
                

            }

            /**
             * p.supertitle.
             */

            p.supertitle { 

                /**
                 * Sizing.
                 */

                font-size: 24px;
                line-height: 29px;

                /**
                 * Margin.
                 */

                margin-bottom: 24px;

            }

            /**
             * p.description.
             */

            p.description {

                /**
                 * Sizing.
                 */

                font-size: 24px;
                line-height: 29px;

            }

        }



    }

}
