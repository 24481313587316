/**
 * .common-panel-contentsscreen-wrapper.
 */

.common-panel-contentsscreen-wrapper {

    /**
     * Sizing.
     */

    height: calc(100vh - $nav-bar-height) !important;

    /**
     * .common-panel-contentsscreen-container.
     */

    .common-panel-contentsscreen-container {
        
        /**
         * .nav-wrapper .footer .nav-bar-wrapper.
         */

        .nav-wrapper .footer .nav-bar-wrapper {

            /**
             * Position.
             */

            position: relative;

        }

    }

}
