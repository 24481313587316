/**
 * 
 */

$map-height: 700px;
$mobile-landscape-map-height: 500px;

/**
 * .common-panel-interactivesiteplan-wrapper.
 */

.common-panel-interactivesiteplan-wrapper {

    /**
     * .common-panel-interactivesiteplan-container.
     */

    .common-panel-interactivesiteplan-container {

        /**
         * Padding.
         */

        padding-top: 60px;
        padding-bottom: 60px;

        /**
         * .interactive-siteplan-wrapper.
         */

        .interactive-siteplan-wrapper {

            /**
             * Position.
             */

            position: relative;

            /**
             * Sizing.
             */

            height: $map-height;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Sizing.
                 */

                // height: $mobile-landscape-map-height;
                height: auto;

            }
            
            /**
             * .interaction-message-wrapper.
             */

            .interaction-message-wrapper {

                /**
                 * Padding.
                 */

                padding: 40px 30px 30px 30px;

                /**
                 * @include.
                 */

                @include position-transform-center;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Position.
                     */

                    top: 35%;

                }

                /**
                 * Pointer.
                 */

                pointer-events: none;

                /**
                 * Z-index.
                 */

                z-index: 500;

                /**
                 * Background.
                 */
            
                background-color: white;

                /**
                 * Sizing.
                 */

                width: 400px;
                
                /**
                 * @include.
                 */

                @include breakpoint($bs-extra-small) {

                    /**
                     * Sizing.
                     */

                    max-width: 90%;

                }

                /**
                 * Border.
                 */

                border-radius: 20px;

                /**
                 * Font related.
                 */

                text-align: center;

                /**
                 * Transition.
                 */

                transition: opacity 0.2s ease;

                /**
                 * .title.
                 */

                .title {

                    /**
                     * Font related.
                     */

                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-size: 20px;
                    line-height: 24px;

                    /**
                     * Margin.
                     */

                    margin-bottom: 12px;

                }

                /**
                 * p.instructions.
                 */

                p.instructions {

                    /**
                     * Font related.
                     */

                    line-height: 22px;

                }

                /**
                 * &.hidden.
                 */

                &.hidden {

                    /**
                     * Opacity.
                     */

                    opacity: 0;

                }

            }

            /**
             * .north-arrow.
             */

            .north-arrow {

                /**
                 * Position.
                 */

                position: absolute;
                top: 15px;
                left: 15px;

                /**
                 * Z-index.
                 */

                z-index: 500;
            
                /**
                 * Sizing.
                 */
                
                width: 60px;

            }

            /**
             * .key-wrapper.
             */

            .key-wrapper {

                /**
                 * Position.
                 */

                position: absolute;
                right: 20px;
                bottom: 20px;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Sizing.
                     */

                    position: relative;
                    right: auto;
                    bottom: auto;

                    /**
                     * Margin.
                     */

                    margin-top: 20px;

                }

                /**
                 * Z-index.
                 */

                z-index: 500;

                /**
                 * Background.
                 */
            
                background-color: white;

                /**
                 * Padding.
                 */

                padding: 10px;

                /**
                 * Border.
                 */

                border-radius: 10px;
                border: 1px solid rgba( $brand-four, 0.8 );



                /**
                 * ul.
                 */

                ul {

                    /**
                     * li.
                     */

                    li {

                        /**
                         * Display.
                         */

                        display: flex;

                        /**
                         * Flex.
                         */

                        align-items: center;

                        /**
                         * img.
                         */

                        img {

                            /**
                             * Sizing.
                             */

                            width: 40px;
                            height: 40px;

                        }

                        /**
                         * p.
                         */

                        p {

                            /**
                             * Margin.
                             */

                            margin: 0 0 0 5px;

                            /**
                             * Font related.
                             */

                            font-size: 14px;
                            line-height: 20px;

                        }

                    }

                }

            }

        }

        /**
         * .interactive-siteplan-container.
         */

        .interactive-siteplan-container {

            /**
             * Sizing.
             */

            height: $map-height;
            width: 100%;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Sizing.
                 */

                // height: $mobile-landscape-map-height;
                height: auto;

            }

            /**
             * img.siteplan.
             */

            img.siteplan {

                /**
                 * Sizing.
                 */

                max-width: 100%;

            }

            /**
             * #interactive-siteplan.
             */

            #interactive-siteplan {

                /**
                 * Sizing.
                 */

                height: $map-height;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Sizing.
                     */

                    height: $mobile-landscape-map-height;

                }
                
            }

            /**
             * .leaflet-control-attribution.
             */

            .leaflet-control-attribution {

                /**
                 * Display.
                 */

                display: none;

            }

            /**
             * .leaflet-popup.
             */

            .leaflet-popup {

                /**
                 * 
                 */

                &.color-F08667 .leaflet-popup-tip { background-color: #F08667; }
                &.color-F5A14C .leaflet-popup-tip { background-color: #F5A14C; }
                &.color-6BAFBE .leaflet-popup-tip { background-color: #6BAFBE; }
                &.color-29C0B9 .leaflet-popup-tip { background-color: #29C0B9; }
                &.color-BCC15A .leaflet-popup-tip { background-color: #BCC15A; }
                &.color-606B46 .leaflet-popup-tip { background-color: #606B46; }

                /**
                 * &.popup-position-below.
                 */

                &.popup-position-below {

                    /**
                     * Position.
                     */

                    bottom: auto !important;
                    top: auto !important;
                
                    /**
                     * Margin.
                     */

                    margin-top: 50px;

                    /**
                     * .leaflet-popup-tip-container.
                     */

                    .leaflet-popup-tip-container {

                        /**
                         * Position.
                         */

                        top: -19px;

                        /**
                         * Margin.
                         */

                        margin-left: -19px;

                        /**
                         * Overflow.
                         */

                        overflow: hidden;

                        /**
                         * Pointer.
                         */

                        pointer-events: none;

                        /**
                         * Transform.
                         */

                        transform: rotate(180deg);

                    }

                }

            }

            /**
             * .leaflet-top.
             */

            .leaflet-top {

                /**
                 * Z-index.
                 */

                z-index: 400;

            }

            /**
             * .leaflet-control.
             */

            .leaflet-control {

                /**
                 * Margin.
                 */

                margin: 20px;

                /**
                 * Border.
                 */

                border-radius: 5px;
                border: 1px solid rgba( black, 0.1 );

                /**
                 * Overflow.
                 */

                overflow: hidden;

                /**
                 * .leaflet-control-zoom-in.
                 */

                .leaflet-control-zoom-in {

                    /**
                     * Display.
                     */

                    display: flex;

                    /**
                     * Flex.
                     */

                    justify-content: center;
                    align-items: center;

                    /**
                     * Padding.
                     */

                    padding: 25px;

                }

                /**
                 * .leaflet-control-zoom-out.
                 */

                .leaflet-control-zoom-out {

                    /**
                     * Display.
                     */

                    display: flex;

                    /**
                     * Flex.
                     */

                    justify-content: center;
                    align-items: center;

                    /**
                     * Padding.
                     */

                    padding: 25px;

                }

            }

            /**
             * .leaflet-popup-content.
             */

            .leaflet-popup-content {

                /**
                 * Margin.
                 */

                margin: 0;

            }

            /**
             * .leaflet-popup-content-wrapper.
             */

            .leaflet-popup-content-wrapper {
                
                /**
                 * Background.
                 */

                background-color: $brand-two;

                /**
                 * Overflow.
                 */

                overflow: hidden;

                /**
                 * Font related.
                 */

                color: white;
                text-align: center;

                /**
                 * Padding.
                 */

                padding: 0;

                /**
                 * p.
                 */

                p {

                    /**
                     * Margin.
                     */

                    margin: 0;

                }

                /**
                 * .text-inset.
                 */

                .text-inset {

                    /**
                     * Padding.
                     */

                    padding: 15px 10px 15px 10px;

                }

                /**
                 * .image-container.
                 */

                .image-container {

                    /**
                     * Sizing.
                     */

                    width: 360px;
                    height: 180px;

                    /**
                     * img
                     */

                    img {

                        /**
                         * Display.
                         */

                        display: none

                    }

                }

                /**
                 * .title.
                 */

                .title {

                    /**
                     * Font related.
                     */

                    text-transform: uppercase;
                    letter-spacing: 1px;                   
                    font-size: 16px;
                    font-weight: bold;

                    /**
                     * Margin.
                     */

                    margin-bottom: 3px;

                }

                /**
                 * .description.
                 */

                .description {

                    /**
                     * Padding.
                     */

                    padding-left: 20px;
                    padding-right: 20px;

                    /**
                     * Font related.
                     */

                    font-size: 15px;
                    line-height: 17px;

                }

            }

            /**
             * .leaflet-popup-tip.
             */

            .leaflet-popup-tip {

                /**
                 * Background.
                 */

                background-color: $brand-two;

            }

        }

    }

}
