/**
 * .common-panel-blocksetc-wrapper.
 */

.common-panel-blocksetc-wrapper {

    /**
     * Overlfow.
     */

    overflow: hidden;

    /**
     * .common-panel-blocksetc-container.
     */

    .common-panel-blocksetc-container {

        /**
         * Background.
         */

        // background-color: palegoldenrod;

        /**
         * .col-text.
         */

        .col-text {

            /**
             * Padding.
             */

            padding-top: 160px;            

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Padding.
                 */

                padding-top: 60px;
                padding-bottom: 30px;

                /**
                 * Font related.
                 */

                text-align: center;

            }

        }

        /**
         * .col-image.
         */

        .col-image {

            /**
             * .image-container.
             */

            .image-container {

                /**
                 * Sizing.
                 */

                min-height: 950px;
                width: 160%;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Sizing.
                     */

                    max-width: 100%;
                    min-height: 350px;

                }


            }

            /**
             * img.image.
             */

            img.image {

                /**
                 * Display.
                 */

                display: none;

            }

        }

        /**
         * .row-group1.
         */

        .row-group1 {

            /**
             * .group1.
             */
    
            .group1 {
    
                /**
                 * Background.
                 */
    
                // background-color: paleturquoise;
    
                /**
                 * Display.
                 */
    
                display: flex;
    
                /**
                 * Flex.
                 */
    
                flex-direction: column;
                align-items: flex-end;
    
                /**
                 * Margin.
                 */
    
                margin-bottom: 20px;
        
                /**
                 * img.
                 */
    
                img {
    
                    /**
                     * Display.
                     */
    
                    display: none;
    
                }
    
                /**
                 * img.graphic.
                 */
    
                img.graphic {
    
                    /**
                     * Display.
                     */
    
                    display: block;
                    
                    /**
                     * Posiiton.
                     */
    
                    position: absolute;
                    top: 20px;
                    left: -20px;
    
                    /**
                     * Transform.
                     */
    
                    transform: translateY(-100%);
    
                    /**
                     * Z-index.
                     */
    
                    z-index: 1;
    
                    /**
                     * Sizing.
                     */
    
                    width: 220px;

                }
    
            }

        }

        /**
         * .row-group2.
         */

        .row-group2 {

            /**
             * Position.
             */

            position: relative;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Background.
                 */

                // background-color: palegreen;

                /**
                 * Flex.
                 */

                flex-direction: column-reverse;

            }

            /**
             * .col-text.
             */

            .col-text {

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Padding.
                     */

                    padding-bottom: 150px;

                    /**
                     * Font related.
                     */

                    text-align: center;

                }

            }

            /**
             * .col-image.
             */

            .col-image {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;
                align-items: flex-end;

                /**
                 * Z-index.
                 */

                z-index: 3;

            }

            /**
             * .graphic1.
             */

            .graphic1 {

                /**
                 * Posiiton.
                 */

                position: absolute;
                top: 0;
                left: 0;

                /**
                 * Transform.
                 */

                transform: translateY(-102%) scaleX(-100%);

                /**
                 * Sizing.
                 */

                max-width: 140px;

                /**
                 * 
                 */

                z-index: 2;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Display.
                     */

                    display: none;

                    /**
                     * Position.
                     */

                    top: 0;
                    left: 50%;

                    /**
                     * Transform.
                     */

                    transform: translateY(-102%) scaleX(-100%) translateX(50%);

                    /**
                     * Sizing.
                     */

                    max-width: 120px;

                }

            }

            /**
             * .graphic2.
             */

            .graphic2 {

                /**
                 * Posiiton.
                 */

                position: absolute;
                right: 0;
                bottom: 0;

                /**
                 * Sizing.
                 */

                height: 95%;

                /**
                 * Transform.
                 */

                transform: translateX(100%) scaleX(-100%) rotate(-30deg);

                /**
                 * Z-index.
                 */

                z-index: 3;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Position.
                     */

                    bottom: 0;
                    left: 50%;

                    /**
                     * Transform.
                     */

                    transform: translateY(30%) translateX(-50%);

                    /**
                     * Sizing.
                     */

                    max-width: 120px;
                    height: auto;

                }

            }

            /**
             * .image-container.
             */

            .image-container {

                /**
                 * @include.
                 */

                @include strip-above-image-container;

            }

            /**
             * &::after.
             */

            &::after {

                /**
                 * Content.
                 */

                content: '';

                /**
                 * Position.
                 */

                position: absolute;
                bottom: 0;
                left: 50%;

                /**
                 * Sizing.
                 */

                width: 2000px;
                height: 30%;

                /**
                 * Z-index.
                 */

                z-index: 0;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Content.
                     */

                    content: none;

                }

            }

        }


        /**
         * .col-b.
         */

        .col-b {

            /**
             * Background.
             */
            
            // background-color: red;

            /**
             * .inset.
             */

            .inset {

                /**
                 * Display.
                 */

                display: flex;
                
                /**
                 * Flex.
                 */

                flex-direction: column;

            }

            /**
             * .heading-wrapper.
             */

            .heading-wrapper {

                /**
                 * Padding.
                 */

                padding-left: $heading-wrapper-left-padding;

            }

        }

        /**
         * .heading-wrapper.
         */

        .heading-wrapper {

            /**
             * 
             */

            background-color: $brand-one;

            /**
             * Padding.
             */

            padding-top: 30px;
            padding-bottom: 20px;

            /**
             * Margin.
             */

            margin-bottom: 20px;

            /**
             * p.
             */

            p {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * Font related.
                 */

                font-size: 32px;
                line-height: 36px;
                color: white;

                /**
                 * Z-index.
                 */

                z-index: 1;

            }

            /**
             * &.spacer.
             */

            &.spacer {

                /**
                 * Background.
                 */

                background-color: transparent;

            }

        }

    }

}
