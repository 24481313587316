/**
 * .fullscreen-flatvideo-embed-wrapper.
 */

.fullscreen-flatvideo-embed-wrapper {

    /**
     * Display.
     */

    display: flex;

    /**
     * Flex.
     */

    align-items: center;
    justify-content: center;

    /**
     * Position.
     */

    position: relative;

    /**
     * Overflow.
     */

    overflow: hidden;

    /**
     * Sizing.
     */

    height: 100%;

    /**
     * 
     */

    .fullscreen-flatvideo-embed-container {

        /**
         * Sizing.
         */

        height: 100%;
        width: 1000%;

        /**
         * Pading.
         */

        padding-bottom: 56.25%; 

        /**
         * 
         */

        position: absolute;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;

        /**
         * iframe.
         */

        iframe {

            /**
             * Position.
             */

            position: absolute;

            /**
             * Sizing.
             */

            width: 100%;
            height: 100%;

        }

    }

}

