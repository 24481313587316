/**
 * .common-panel-educationtimelines-wrapper.
 */

.common-panel-educationtimelines-wrapper {

    /**
     * .common-panel-educationtimelines-container.
     */

    .common-panel-educationtimelines-container {

        /**
         * .col-type.
         */

        .col-type {

            /**
             * .graphic-wrapper.
             */

            .graphic-wrapper {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * Margin.
                 */

                margin-bottom: 15px;

                /**
                 * Sizing.
                 */

                height: 80px;

                /**
                 * img.
                 */
            
                img {

                    /**
                     * Sizing.
                     */

                    height: 68px;

                    /**
                     * &.car.
                     */

                    &.car {

                        /**
                         * Sizing.
                         */

                        height: 49px;

                    }

                }

            }

            /**
             * .timeline.
             */

            .timeline {

                /**
                 * Background.
                 */

                // background-color: palevioletred;

            }

            /**
             * .heading-wrapper.
             */

            .heading-wrapper {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                justify-content: center;
                align-items: center;

                /**
                 * Sizing.
                 */

                min-height: 200px;

                /**
                 * Margin.
                 */

                margin-bottom: 40px;

                /**
                 * .heading.
                 */

                .heading {

                    /**
                     * Margin.
                     */

                    margin-bottom: 0;

                    /**
                     * Font related.
                     */

                    font-family: AdventuresUnlimitedScriptRegular;
                    font-size: 50px;
                    line-height: 40px;
                    color: white;
                    text-align: center;
                    text-transform: none;

                }

            }

            /**
             * .items-wrapper.
             */

            .items-wrapper {
                
                /**
                 * Margin.
                 */

                margin-bottom: 10px;

                /**
                 * Background.
                 */

                // background-color: orange;

                /**
                 * .item-wrapper.
                 */

                .item-wrapper {

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Padding.
                     */

                    padding-bottom: 20px;
                    padding-left: 0;

                    /**
                     * &:last-child.
                     */

                    &:last-child {

                        /**
                         * &::after.
                         */

                        &::after {

                            /**
                             * Content.
                             */

                            content: none;

                        }

                    }

                    /**
                     * &::after.
                     */

                    &::after {

                        /**
                         * Content.
                         */

                        content: '';

                        /**
                         * Sizing.
                         */

                        width: 2px;
                        height: 100%;

                        /**
                         * Position.
                         */
                    
                        position: absolute;
                        top: 6px;
                        left: 8px;

                        /**
                         * Background.
                         */

                        background-color: $brand-one;

                    }

                    /**
                     * p.
                     */

                    p {

                        /**
                         * Margin.
                         */

                        margin-bottom: 2px;

                        /**
                         * Padding.
                         */

                        padding-left: 30px;

                    }

                    /**
                     * p.title.
                     */

                    p.title {

                        /**
                         * Position.
                         */

                        position: relative;

                        /**
                         * Font related.
                         */

                        font-weight: bold;

                        /**
                         * &::after.
                         */

                        &::after {

                            /**
                             * Content.
                             */

                            content: '';

                            /**
                             * Sizing.
                             */

                            width: 17px;
                            height: 17px;

                            /**
                             * Border.
                             */

                            border-radius: 100%;

                            /**
                             * Position.
                             */
                        
                            position: absolute;
                            top: 50%;
                            left: 0;

                            /**
                             * Transform.
                             */

                            transform: translateY(-50%);

                            /**
                             * Background.
                             */

                            background-color: $brand-one;

                        }

                    }

                    /**
                     * p.time.
                     */

                    p.time {

                        /**
                         * Margin.
                         */

                        margin-bottom: 10px;

                    }

                    /**
                     * p.website.
                     */

                    p.website {

                        /**
                         * a.
                         */

                        a {

                            /**
                             * Font related.
                             */

                            color: inherit;
                            text-decoration: none;

                            /**
                             * &:hover.
                             */

                            &:hover {

                                /**
                                 * Font related.
                                 */

                                color: $brand-one;

                            }

                        }

                    }


                    /**
                     * img.
                     */

                    img {

                        /**
                         * Margin.
                         */

                        margin-left: 30px;

                    }

                }

            }

        }

    }

}
