/**
 * .common-panel-fullscreenimage-wrapper.
 */

.common-panel-fullscreenimage-wrapper {

    /**
     * Sizing.
     */

    height: calc(100vh - $nav-bar-height) !important;

    /**
     * @include.
     */

    @include breakpoint($b-df-mobile-landscape) {

        /**
         * Sizing.
         */

        height: 400px !important;

    }

    /**
     * Overflow.
     */

    // overflow: hidden;
    overflow-x: clip;

    /**
     * .common-panel-fullscreenimage-container.
     */

    .common-panel-fullscreenimage-container {

        /**
         * 
         */

        img {

            /**
             * Display.
             */

            display: none;

        }

        /**
         * .inset.
         */

        .inset {

            /**
             * Sizing.
             */

            height: calc(100vh - $nav-bar-height) !important;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Sizing.
                 */

                height: 400px !important;

            }

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            flex-direction: column;
            justify-content: flex-end;

        }

        /**
         * &.image-position-left.
         */

        &.image-position-left {
            
            /**
             * 
             */

            .image-container {

                /**
                 * 
                 */

                background-position: left;

            }

        }

        /**
         * .image-container.
         */

        .image-container {

            /**
             * Position.
             */

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            /**
             * Background.
             */

            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

        }

        /**
         * .col-title.
         */

        .col-title {

            /**
             * Position.
             */

            position: relative;

            /**
             * Z-index.
             */

            z-index: 1;

            /**
             * > div.
             */

            > div {

                /**
                 * Background.
                 */

                background-color: $brand-one;

                /**
                 * @include.
                 */

                @include background-extension-right;

                /**
                 * Margin.
                 */

                margin-bottom: 40px;

                /**
                 * Padding.
                 */

                padding-top: 30px;
                padding-bottom: 20px;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * &::after.
                     */

                    &::after {

                        /**
                         * 
                         */

                        left: -200px;

                    }

                    /**
                     * Margin.
                     */

                    margin-bottom: 0;

                    /**
                     * Transform.
                     */

                    transform: translateY(100%);

                }

            }

        }

        /**
         * .title.
         */

        .title {

            /**
             * Position.
             */

            position: relative;

            /**
             * Z-index.
             */
    
            z-index: 1;

            /**
             * Margin.
             */

            margin-bottom: 0;

            /**
             * Font related.
             */

            font-family: AdventuresUnlimitedScriptRegular;
            font-size: 80px;
            line-height: 80px;
            color: white;

            /**
             * Transform.
             */

            transform: translateY(-5px);

            /**
             * Padding.
             */

            padding-left: $heading-wrapper-left-padding;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Padding.
                 */

                padding-left: 0;

            }
            
            /**
             * @include.
             */

            @include breakpoint($b-df-tablet) {

                /**
                 * Font related.
                 */

                font-size: 58px;
                line-height: 40px;
                
            }
            
        }

    }

}
