/**
 * .common-panel-centeredlogo-wrapper.
 */

.common-panel-centeredlogo-wrapper {

    /**
     * .common-panel-centeredlogo-container.
     */

    .common-panel-centeredlogo-container {

        /**
         * Font related.
         */

        text-align: center;

        /**
         * img.
         */

        img {

            /**
             * @include.
             */

            @include breakpoint($bs-extra-small) {

                /**
                 * Sizing.
                 */

                max-width: 140px;

            }

        }

    }

}
