/**
 * .simple-layouts-text.
 */

.simple-layouts-text {

    /**
     * a.
     */

    a {

        /**
         * Font related.
         */

        color: $brand-one;

        /**
         * &:hover.
         */

        &:hover {

            /**
             * Font related.
             */

            color: $brand-two;

        }

    }

}
