/**
 * .common-panel-colorbarwithtext-wrapper.
 */

.common-panel-colorbarwithtext-wrapper {

    /**
     * .common-panel-colorbarwithtext-container.
     */

    .common-panel-colorbarwithtext-container {

        /**
         * Background.
         */

        // background-color: palegreen;

        /**
         * Font related.
         */

        text-align: center;
        color: $brand-three;

        /**
         * &.align-center.
         */

        &.align-center {

            /**
             * 
             */

            .simple-layouts {

                /**
                 * .simple-layouts-text.
                 */

                .simple-layouts-text {

                    /**
                     * Display.
                     */

                    display: flex;

                    /**
                     * Flex.
                     */

                    justify-content: center;

                }

            }

        }

        /**
         * &.limit-to-700px.
         */

        &.limit-to-700px {

            /**
             * .simple-layouts.
             */

            .simple-layouts {

                /**
                 * p.
                 */

                p {

                    /**
                     * Sizing.
                     */
    
                    max-width: 700px;

                }


            }


        }

        /**
         * .heading.
         */

        .heading {

            /**
             * Margin.
             */

            margin-bottom: 20px;

            /**
             * &.font-size-large.
             */

            &.font-size-large {

                /**
                 * Font related.
                 */

                font-size: 36px;
                line-height: 45px;

            }

        }

        /**
         * &.script.
         */

        &.script {

            /**
             * .heading.
             */

            .heading {

                /**
                 * Font related.
                 */

                font-family: AdventuresUnlimitedScriptRegular;
                font-size: 80px;
                line-height: 55px;
                color: $brand-five;
                text-transform: none;

            }

        }

        /**
         * &.narrow-column-medium.
         */

        &.narrow-column-medium {

            /**
             * .inset.
             */
        
            .inset {

                /**
                 * .simple-layouts.
                 */

                .simple-layouts {

                    /**
                     * Padding.
                     */

                    padding-left: 15%;
                    padding-right: 15%;

                }

            }

        }

    }

}
