/**
 * 
 */

/**
 * .apartments-panel-floorplans-wrapper.
 */

.apartments-panel-floorplans-wrapper {

    /**
     * .apartments-panel-floorplans-container.
     */

    .apartments-panel-floorplans-container {

        /**
         * h3.
         */

        h3 {

            /**
             * Margin.
             */

            margin-bottom: 20px;

        }

        /**
         * hr.
         */

        hr {

            /**
             * Sizing.
             */

            height: 0px;

            /**
             * Border.
             */

            border-top: 1px solid $brand-four;

            /**
             * Margin.
             */

            margin-top: 40px;
            margin-bottom: 40px;


        }

        /**
         * .features-list.
         */

        .features-list {

            /**
             * Margin.
             */

            margin-left: 15px;

            /**
             * li.
             */

            li {

                /**
                 * List.
                 */

                list-style-type: "—";

                /**
                 * Padding.
                 */

                padding-left: 20px;

                /**
                 * Margin.
                 */

                margin-bottom: 12px;

            }

        }

        /**
         * .row-floorplans.
         */

        .row-floorplans {

            /**
             * Padding.
             */

            padding-top: 20px;

            /**
             * .col-floorplan.
             */

            .col-floorplan {

                /**
                 * 
                 */

                .heading {

                    /**
                     * 
                     */

                    font-size: 16px;

                }

                /**
                 * .title.
                 */

                .title {

                    /**
                     * Font related.
                     */

                    text-transform: uppercase;

                }

                /**
                 * .col-locator.
                 */

                .col-locator {

                    /**
                     * .image-container.
                     */

                    .image-container {

                        /**
                         * Display.
                         */

                        display: flex;

                        /**
                         * Flex.
                         */

                        justify-content: center;

                        /**
                         * Sizing.
                         */

                        height: auto;

                        /**
                         * Background.
                         */

                        background-image: none !important;
                        background-color: transparent !important;

                        /**
                         * img.
                         */

                        img {

                            /**
                             * Display.
                             */

                            display: block;

                            /**
                             * Sizing.
                             */

                            max-height: 500px;

                        }

                    }

                }

                /**
                 * .image-container.
                 */

                .image-container {

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Background.
                     */

                    background-color: rgba( black, 0.03 );

                    /**
                     * Sizing.
                     */

                    height: 700px;

                    /**
                     * Margin.
                     */

                    margin-bottom: 30px;

                    /**
                     * @include.
                     */

                    @include breakpoint($bs-extra-small) {

                        /**
                         * Background.
                         */

                        background-size: contain;

                        /**
                         * Sizing.
                         */

                        height: 400px;

                    }

                    /**
                     * img.
                     */

                    img {

                        display: none;

                    }

                    /**
                     * a.enlarge
                     */

                    a.enlarge {

                        /**
                         * Display.
                         */

                        display: block;

                        /**
                         * Position.
                         */

                        position: absolute;
                        bottom: 30px;
                        right: 30px;

                        /**
                         * 
                         */

                        height: 60px;
                        width: 60px;

                        /**
                         * Border.
                         */

                        border-radius: 10px;

                        /**
                         * 
                         */

                        background-color: white;;

                        /**
                         * Font related.
                         */

                        text-decoration: none;

                        /**
                         * 
                         */

                        .symbol {

                            /**
                             * Font related.
                             */

                            color: black;
                            font-size: 26px;
                            line-height: 20px;

                            position: absolute;
                            top: 47%;
                            left: 51%;

                            transform: translateX(-50%) translateY(-50%);

                        }

                        /**
                         * .text.
                         */

                        .text {

                            /**
                             * Display.
                             */

                            display: none;

                        }

                    }

                }

                /**
                 * .dims-list.
                 */

                .dims-list {

                    /**
                     * Margin.
                     */
                
                    margin-bottom: 10px;

                    /**
                     * .title.
                     */

                    .title {

                        /**
                         * Margin.
                         */

                        margin-bottom: 3px;

                        /**
                         * Font related.
                         */

                        font-weight: bold;

                    }

                    /**
                     * .metres.
                     */

                    .metres {

                        /**
                         * Padding.
                         */

                        padding-right: 15px;

                    }

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Margin.
                         */

                        margin-bottom: 40px;

                    }

                }

            }

        }

        /**
         * .row-totalfloorarea.
         */

        .row-totalfloorarea {

            /**
             * .title.
             */

            .title {

                /**
                 * Font related.
                 */

                text-transform: uppercase;

            }

            /**
             * .total-area-group.
             */

             .total-area-group {

                /**
                 * .title.
                 */

                .title {

                    /**
                     * Font related.
                     */

                    font-weight: bold;
                    margin-bottom: 5px;

                }

                /**
                 * .areas-list.
                 */

                .areas-list {

                    /**
                     * 
                     */

                    li {

                        /**
                         * Display.
                         */

                        display: inline-block;

                        /**
                         * &.aream2.
                         */

                        &.aream2 {

                            /**
                             * Padding.
                             */

                            padding-right: 15px;

                        }

                    }

                }

                /**
                 * Margin.
                 */

                margin-bottom: 20px;

            }


        }


        
        /**
         * .row-key-and-locator.
         */

        .row-key-and-locator {

            /**
             * Margin.
             */

            margin-bottom: 40px;

            /**
             * .col-key.
             */

            .col-key {

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Margin.
                     */

                    margin-bottom: 20px;

                }

                /**
                 * img.
                 */

                img {

                    /**
                     * Sizing.
                     */

                    height: 170px;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Sizing.
                         */

                        max-width: 100%;
                        height: auto;

                    }                    

                }

            }

            /**
             * .col-locator.
             */

            .col-locator {

                /**
                 * img.
                 */

                img {

                    /**
                     * Sizing.
                     */

                    width: 300px;

                }

            }

        }

        /**
         * .row-disclaimer.
         */

        .row-disclaimer {

            /**
             * Padding.
             */

            padding-bottom: 100px;

            /**
             * p.
             */

            p {

                /**
                 * Margin.
                 */

                margin-bottom: 80px;

                /**
                 * Font related.
                 */

                font-size: 12px;
                line-height: 18px;

            }


        }

    }

}
