/**
 * .simple-layouts-list.
 */

.simple-layouts-list {

    /**
     * Font related.
     */

    text-align: left;

    /**
     * List.
     */

    list-style: inside;

    /**
     * ul.
     */

    ul {

        /**
         * Margin.
         */

        margin-left: 24px;

        /**
         * li.
         */

        li {

            /**
             * Margin.
             */

            margin-bottom: 12px;

            /**
             * Font related.
             */

            font-size: 18px;
            line-height: 24px;

        }

        /**
         * &.font-awesome.
         */

        &.font-awesome {

            /**
             * li.
             */

            li {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * &::before.
                 */

                &::before {

                    /**
                     * Position.
                     */

                    position: absolute;
                    top: 0;
                    left: 0;

                    /**
                     * Margin.
                     */

                    margin-left: -24px;
                    margin-top: 2px;

                    /**
                     * Font related.
                     */

                    font-family: 'Font Awesome 6 Pro';
                    font-weight: 900;
                    color: inherit;

                }

            }

        }

    }

}
