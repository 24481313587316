/**
 * 
 */

@mixin image-container {

    /**
     * 
     */

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;    

}

/**
 * 
 */

.image-container {

    /**
     * 
     */

    @include image-container();

}