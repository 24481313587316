/**
 * @mixin.
 */

@mixin position-transform-center {

    /**
     * Position.
     */

    position: absolute;
    top: 50%;
    left: 50%;

    /**
     * Transform.
     */

    transform: translateX(-50%) translateY(-50%);

}