/**
 * .nav-wrapper.
 */

.nav-wrapper {

    /**
     * Position.
     */

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /**
     * Z-index.
     */

    z-index: 599;

    /**
     * Background.
     */
    
    // background-color: $brand-two;

    /**
     * @media.
     */

    @media (max-height: 1080px) {

        /**
         * Overflow.
         */

        overflow-y: scroll;

        /**
         * &::-webkit-scrollbar.
         */

        &::-webkit-scrollbar {
            display: none;
        }
        
        /**
         * Scroll.
         */

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

    }

    /**
     * Opacity.
     */

    opacity: 0;
    
    /**
     * Pointer.
     */

    pointer-events: none;

    /**
     * Transition.
     */

    transition: opacity 0.2s ease;

    /**
     * &.show.
     */

    &.show {

        /**
         * Opacity.
         */

        opacity: 1;

        /**
         * Pointer.
         */

        pointer-events: all;

    }

    /**
     * &::after.
     */

    &::after {

        /**
         * Content.
         */

        content: '';

        /**
         * Position.
         */

        position: fixed;
        top: 0;
        left: 0;
        right: 0;

        /**
         * Sizing.
         */

        height: $strip-top-height;

        /**
         * Background.
         */

        background-color: $brand-one;

    }

    /**
     * &::before.
     */

    &::before {

        /**
         * Content.
         */

        content: '';

        /**
         * Position.
         */

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        /**
         * Background.
         */

        background-color: rgba( $brand-two, 0.9 );
        
        /**
         * Filter.
         */

        

        /**
         * Z-index
         */

        z-index: -1;
        
    }

    /**
     * .background-image-wrapper.
     */

    .background-image-wrapper {

        /**
         * 
         */

        // background-color: red;

        /**
         * Sizing.
         */

        height: 100%;
        width: 100%;

        /**
         * Z-index.
         */

        z-index: -1;

        /**
         * Position.
         */

        position: fixed;
        top: 0;
        left: 0;

        /**
         * Opacity.
         */

        opacity: 0.8;

        /**
         * img.
         */

        img {

            /**
             * Sizing.
             */

            height: 100%;

            /**
             * Position.
             */

            position: absolute;
            left: 65%;
            top: 0;

            /**
             * Transform.
             */

            transform: translateX(-50%);

        }

    }

    /**
     * .image.
     */

    .image {

        /**
         * Sizing.
         */

        width: 70px;

        /**
         * Margin.
         */

        margin-bottom: 40px;

        /**
         * @include.
         */

        @include breakpoint($bs-extra-small) {

            /**
             * Sizing.
             */

            width: 45px;

            /**
             * Margin.
             */

            margin-bottom: 25px;

        }

    }

    /**
     * div.
     */

    div {

        /**
         * Sizing.
         */

        // height: 100%;

    }

    /**
     * .heading.
     */

    .heading {

        /**
         * Font related.
         */
    
        text-transform: uppercase;
        font-size: 50px;
        line-height: 56px;
        letter-spacing: 5px;
        color: $brand-three;

        /**
         * Margin.
         */

        margin-bottom: 60px;

        /**
         * @include.
         */

        @include breakpoint($bs-extra-small) {

            /**
             * Font related.
             */

            font-size: 30px;
            line-height: 1.2;

            /**
             * Margin.
             */

            margin-bottom: 30px;

        }

    }

    /**
     * ul.main-menu.
     */

    ul.main-menu {

        /**
         * 
         */

        // background-color: palegoldenrod;

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        flex-wrap: wrap;

        /**
         * @include.
         */

        @include breakpoint($bs-extra-small) {

            /**
             * Sizing.
             */

            padding-left: 10px;
            padding-right: 10px;

        }

        /**
         * li.
         */

        li {

            /**
             * Border.
             */

            // border: 2px solid purple;

            /**
             * Sizing.
             */

            width: 33.3%;

            /**
             * Margin.
             */

            margin-bottom: 30px;

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            justify-content: center;

            /**
             * &.disabled.
             */

            &.disabled {

                /**
                 * Opacity.
                 */

                opacity: 0.4;

                /**
                 * Pointer.
                 */

                pointer-events: none;

            }

            /**
             * @include.
             */

            @include breakpoint($bs-extra-small) {

                /**
                 * Margin.
                 */

                margin-bottom: 20px;

            }

            /**
             * &.active.
             */

            &.active {

                /**
                 * Opacity.
                 */

                a {

                    /**
                     * .number.
                     */

                    .number {

                        /**
                         * Font related.
                         */

                        color: white;

                    }

                }

            }

            /**
             * a.
             */

            a {

                /**
                 * Background.
                 */

                // background-color: aqua;

                /**
                 * Border.
                 */

                // border: 2px solid red;

                /**
                 * Font related.
                 */

                color: white;

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */
            
                flex-direction: column;
                align-items: center;

                /**
                 * Sizing.
                 */

                width: 150px;
                /**
                 * Font related.
                 */

                text-decoration: none;

                /**
                 * .number.
                 */

                .number {

                    /**
                     * Background.
                     */

                    // background-color: palegreen;

                    /**
                     * Display.
                     */

                    display: block;

                    /**
                     * Font related.
                     */

                    font-size: 56px;
                    color: $brand-one;
                    font-weight: bold;

                    /**
                     * Margin.
                     */

                    margin-bottom: 10px;

                    /**
                     * @include.
                     */

                    @include breakpoint($bs-extra-small) {

                        /**
                         * Font related.
                         */

                        font-size: 40px;

                    }

                }

                /**
                 * .title.
                 */

                .title {

                    /**
                     * Background.
                     */

                    // background-color: paleturquoise;

                    /**
                     * Display.
                     */

                    display: block;

                    /**
                     * Font related.
                     */

                    text-transform: uppercase;
                    text-align: center;
                    font-size: 15px;
                    line-height: 20px;
                    letter-spacing: 1px;

                    /**
                     * @include.
                     */

                    @include breakpoint($bs-extra-small) {

                        /**
                         * Font related.
                         */

                        font-size: 12px;
                        line-height: 1.2;

                    }

                }

                /**
                 * &:hover.
                 */

                &:hover {

                    /**
                     * Background.
                     */

                    // background-color: palevioletred;

                    /**
                     * .number.
                     */

                    .number {

                        /**
                         * Font related.
                         */

                        color: white;

                    }

                }

            }

        }

    }

    /**
     * .inset.
     */

    .inset {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        flex-direction: column;

        /**
         * Sizing.
         */

        height: 100%;

        /**
         * @media.
         */

        @media (max-height: 1080px) {

            /**
             * Padding.
             */

            padding-top: 100px;
            // padding-bottom: 100px;

            /**
             * 
             */

            display: block;

        }

        /**
         * @include.
         */

        @include breakpoint($bs-extra-small) {

            /**
             * Padding
             */

            padding-top: 70px;

        }

    }

    /**
     * .content.
     */

    .content {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-grow: 1;

        /**
         * @media.
         */

        @media (max-height: 1080px) {

            /**
             * Padding.
             */

            padding-bottom: 100px;

        }

    }

    /**
     * .footer.
     */

    .footer {

        /**
         * Position.
         */

        position: relative;

        /**
         * Z-index.
         */

        // z-index: 2;

        /**
         * Flex.
         */

        flex-grow: 0;

        /**
         * Padding.
         */

        padding-top: $strip-top-height;

        /**
         * Background.
         */

        background-color: $brand-one;

    }

    /**
     * .menu-container, .menu.
     */

    .menu-container, .menu {

        /**
         * Sizing.
         */

        height: auto;

    }

    /**
     * .menu-container.
     */

    .menu-container {

        /**
         * Sizing.
         */

        width: 550px;

        /**
         * @include.
         */

        @include breakpoint($bs-extra-small) {

            /**
             * Sizing.
             */
            width: 100%;

        }

        /**
         * Margin.
         */

        margin-bottom: 80px;


    }

}
