/**
 * 
 */

.common-panel-herotypea-wrapper + .common-panel-onecolumntext-wrapper  {

    /**
     * .common-panel-onecolumntext-container.
     */

    .common-panel-onecolumntext-container {

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Font related.
             */

            padding-top: 80px;
            padding-bottom: 30px;

        }


    }

}

/**
 * .common-panel-onecolumntext-wrapper.
 */

.common-panel-onecolumntext-wrapper {

    /**
     * .common-panel-onecolumntext-container.
     */

    .common-panel-onecolumntext-container {

        /**
         * Background.
         */

        // background-color: palegreen;

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Font related.
             */

            padding-top: 30px;
            padding-bottom: 30px;

        }

        /**
         * .heading.font-size-large.
         */

        .heading.font-size-large {

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Font related.
                 */

                font-size: 32px;

            }

        }

        /**
         * &.narrow-column-medium.
         */

        &.narrow-column-medium {

            /**
             * .inset.
             */
        
            .inset {

                /**
                 * .simple-layouts.
                 */

                .simple-layouts {

                    /**
                     * Padding.
                     */

                    padding-left: 15%;
                    padding-right: 15%;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-tablet) {

                        /**
                         * Padding.
                         */

                        padding-left: 0;
                        padding-right: 0;

                    }

                }

            }

        }

    }

}
