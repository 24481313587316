/**
 * .common-panel-splashscreen-wrapper.
 */

.common-panel-splashscreen-wrapper {

    /**
     * Background.
     */

    background-color: #505738 !important;

    /**
     * Sizing.
     */

    height: calc(100vh - $nav-bar-height) !important;



    /**
     * .common-panel-splashscreen-container.
     */

    .common-panel-splashscreen-container {

        /**
         * Padding.
         */

        padding: 0;

        /**
         * Sizing.
         */

        height: calc(100vh - $nav-bar-height) !important;
        width: 100%;
        max-width: 100%;

        // /**
        //  * Display.
        //  */
    
        // display: flex;

        // /**
        //  * Flex.
        //  */

        // align-items: center;
        // justify-content: center;

        // /**
        //  * Backgroubd.
        //  */
    
        // background-position: center;
        // background-size: contain;
        // background-repeat: no-repeat;
    
        // /**
        //  * Padding.
        //  */

        // padding-left: 0;
        // padding-right: 0;

        // /**
        //  * Sizing.
        //  */

        // width: 100%;
        // max-width: 100%;

        // /**
        //  * .video-wrapper.
        //  */

        // .video-wrapper {

        //     /**
        //      * Display.
        //      */

        //     display: flex;

        //     /**
        //      * Flex.
        //      */

        //     justify-content: center;
        //     align-items: center;

        //     /**
        //      * Position.
        //      */

        //     position: relative;

        //     /**
        //      * Overflow.
        //      */

        //     overflow: hidden;

        //     /**
        //      * Sizing.
        //      */

        //     width: 100%;
        //     height: 100%;

        //     /**
        //      * .flatvideo-embed-wrapper.
        //      */

        //     .flatvideo-embed-wrapper {

        //         /**
        //          * Position.
        //          */
                
        //         position: absolute;
                
        //         /**
        //          * Sizing.
        //          */
                
        //         width: 2000%;
        //         height: 100%;

        //         /**
        //          * 
        //          */

        //         .flatvideo-embed-container {

        //             /**
        //              * Sizing.
        //              */

        //             height: 100%;
        //             max-width: initial;

        //             /**
        //              * iframe.
        //              */

        //             iframe {

        //                 /**
        //                  * Sizing.
        //                  */

        //                 height: 100%;
        //                 width: 100%;

        //             }

        //         }

        //     }

        // }

    }

}
