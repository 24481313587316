/**
 * .common-panel-twocolumntextandimagecarousel-wrapper.
 */

.common-panel-twocolumntextandimagecarousel-wrapper {

    /**
     * .common-panel-twocolumntextandimagecarousel-container.
     */

    .common-panel-twocolumntextandimagecarousel-container {

        /**
         * .row-main.
         */

        .row-main {

            /**
             * &.column-reverse.
             */

            &.column-reverse {

                /**
                 * Flex.
                 */

                flex-direction: row-reverse;

            }

        }

        /**
         * .col-text.
         */

        .col-text {

            /**
             * Padding.
             */

            padding-top: 100px;

            /**
             * @include.
             */

            @include breakpoint($b-df-desktop) {
                
                /**
                 * Padding.
                 */

                padding-top: 30px;
                
            }

            /**
             * .graphic.
             */

            .graphic {

                /**
                 * Position.
                 */

                position: absolute;
                bottom: 30px;

                /**
                 * Sizing.
                 */

                max-height: 130px;
                max-width: 130px;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-tablet) {
                    
                    /**
                     * Position.
                     */

                    position: relative;
                    top: auto;
                    bottom: auto;

                    /**
                     * Margin.
                     */

                    margin-bottom: 15px;

                }
                
            }   

        }

        /**
         * .col-carousel.
         */

        .col-carousel {

            /**
             * 
             */

            .slider-wrapper {

                /**
                 * 
                 */

                position: relative;

                /**
                 * .arrow-previous,
                 * .arrow-next.
                 */
    
                .arrow-previous,
                .arrow-next {
                    
                    /**
                     * Display.
                     */

                    display: inline-block;

                    /**
                     * Position.
                     */
    
                    position: absolute;

                    /**
                     * Z-index.
                     */

                    z-index: 10;

                    /**
                     * Sizing.
                     */

                    width: 50px;
                    height: 50px;

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * &::after.
                         */

                        &::after {

                            /**
                             * Background.
                             */
    
                            background-color: $brand-two

                        }

                    }

                    /**
                     * svg.
                     */

                    svg {

                        /**
                         * Position.
                         */

                        position: absolute;
                        top: 50%;
                        left: 50%;

                        /**
                         * Transform.
                         */

                        transform: translateX(-50%) translateY(-50%);

                        /**
                         * Z-index.
                         */

                        z-index: 2;

                        /**
                         * Sizing.
                         */
                        
                        width: 35px;

                    }

                    /**
                     * path.
                     */

                    path {

                        /**
                         * Fill.
                         */

                        fill: white;

                    }

                    /**
                     * &::after.
                     */

                    &::after {

                        /**
                         * Content.
                         */

                        content: '';

                        /**
                         * Sizing.
                         */

                        width: 50px;
                        height: 50px;
                        
                        /**
                         * Position.
                         */

                        position: absolute;
                        left: 50%;
                        top: 50%;

                        /**
                         * Transform.
                         */

                        transform: translateX(-50%) translateY(-50%);

                        /**
                         * Border.
                         */

                        border-radius: 100%;

                        /**
                         * Background.
                         */

                        background-color: $brand-one;

                        /**
                         * Z-index.
                         */

                        z-index: 1;

                    }

                }

                /**
                 * .arrow-previous.
                 */
    
                .arrow-previous {

                    /**
                     * Posiotion.
                     */

                    bottom: 25px;
                    left: 25px;

                }

                /**
                 * .arrow-next.
                 */
    
                .arrow-next {

                    /**
                     * Posiotion.
                     */

                    bottom: 25px;
                    right: 25px;

                }

            }

            /**
             * .swiper-container.
             */

            .swiper-container {

                /**
                 * .slide.
                 */

                .slide {


                    /**
                     * .image-container.
                     */

                    .image-container {

                        /**
                         * Sizing.
                         */
                        
                        width: 100%;
                        height: 550px;

                        /**
                         * @include.
                         */

                        @include breakpoint($b-df-tablet) {
                            
                            /**
                             * Sizing.
                             */
                            
                            height: 450px;

                            position: relative; 
                            padding-bottom: 56.25%; 
                            height: 0; 
                            overflow: hidden; 
                            max-width: 100%;

                        }

                        /**
                         * @include.
                         */

                        @include breakpoint($b-desktop-only) {
                            
                            /**
                             * Sizing.
                             */
                            
                            height: 500px;

                        }                        

                        /**
                         * img.
                         */

                        img {

                            /**
                             * Display.
                             */

                            display: none;

                        }

                    }

                }


            }

        }

    }

}
