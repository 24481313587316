/**
 * @mixin.
 */

@mixin background-extension-left() {

    /**
     * @include.
     */

    @include background-extension();

    /**
     * &:after.
     */

    &:after {

        /**
         * Position.
         */

        left: -2000px;
        right: 0;

    }

}
