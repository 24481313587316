/**
 * .simple-layouts-quote.
 */

.simple-layouts-quote {

    /**
     * Display.
     */

    display: flex;

    /**
     * Margin.
     */

    margin-bottom: 35px;
    margin-top: 35px;

    /**
     * Graphic.
     */

    .graphic {

        /**
         * Margin.
         */

        margin-right: 20px;

        /**
         * .icon.
         */
    
        .icon {
    
            /**
             * Sizing.
             */
    
            width: 60px;
    
            /**
             * Margin.
             */

            margin-right: 10px;

        }

        /**
         * svg.
         */

        svg {

            /**
             * path.
             */

            path {

                /**
                 * Fill.
                 */

                fill: $brand-one !important;

            }

        }

    }
    
    /**
     * .text.
     */

    .text {

        /**
         * 
         */

        p {

            /**
             * Font related.
             */

            font-size: 30px;
            line-height: 40px;

        }

        /**
         * .author.
         */

        .author {

            /**
             * Margin.
             */

            margin-top: 15px;

            /**
             * Font related.
             */

            font-size: 18px;
            line-height: 24px;

        }

    }

}
