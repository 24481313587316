/**
 * .fourohfour-page.
 */

.fourohfour-page {

    /**
     * Background.
     */

    background-color: $brand-one;
    
    /**
     * footer.
     */

    footer {

        /**
         * Display.
         */

        display: none;

    }

    /**
     * .pagination/
     */

    .pagination {

        /**
         * Display.
         */

        display: none;

    }

}

/**
 * .fourohfour-wrapper.
 */

.fourohfour-wrapper {

    /**
     * Padding.
     */

    padding-top: 300px;
    padding-bottom: 200px;

    /**
     * .row-hero.
     */

    .row-hero {
        
        /**
         * Font related.
         */

        text-align: center;

        /**
         * .icon.
         */

        .icon {

            /**
             * Sizing.
             */

            max-width: 100px;

            /**
             * Margin.
             */

            margin-bottom: 30px;

        }

    }

    /**
     * .heading.
     */

    .heading {

        /**
         * Margin.
         */

        margin-bottom: 20px;

        /**
         * 
         */

        color: white;

    }

    /**
     * a.
     */

    a {

        /**
         * Font related.
         */

        color: $brand-two;

        /**
         * &:hover.
         */

        &:hover {

            /**
             * Font related.
             */

            color: $brand-four;

        }

    }

}
