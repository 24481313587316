/**
 * 
 */

html {

    /**
     * Margin - for the WP admin bar.
     */

    margin: 0 !important;

    /**
     * Padding.
     */

    padding-bottom: 100px;
    
}

/**
 * body.
 */

body {
    
    /**
     * &.disable-overflow.
     */

    &.disable-overflow {

        /**
         * Overflow.
         */

        overflow: hidden;

    }

}

/**
 * #wpadminbar.
 */

#wpadminbar {

    /**
     * Opacity.
     */

    opacity: 0.2;

}

/**
 * For heading extra.
 */

.display-block-xs  { @include breakpoint($bs-extra-small-only)  { display: block; } }
.display-block-sm  { @include breakpoint($bs-small-only)        { display: block; } }
.display-block-md  { @include breakpoint($bs-medium-only)       { display: block; } }
.display-block-lg  { @include breakpoint($bs-large-only)        { display: block; } }
.display-block-xl  { @include breakpoint($bs-extra-large-only)  { display: block; } }
.display-block-xxl { @include breakpoint($bs-extra-extra-large-only) { display: block; } }

/**
 * .text-align-center.
 */

.text-align-center {

    /**
     * Font related.
     */

    text-align: center;

}

/**
 * .to-upper-case.
 */

.to-upper-case {

    /**
     * Font related.
     */

    text-transform: uppercase;

}

/**
 * .margin-bottom-0.
 */

.margin-bottom-0 {

    /**
     * Margin.
     */

    margin-bottom: 0 !important;

}

/**
 * h1, h2, h3, h4, h5.
 */

h1, h2, h3, h4, h5 {

    /**
     * Font related.
     */

    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    letter-spacing: 2px;

}

/**
 * h1.
 */

h1 {

    /**
     * &.font-size-small.
     */

    &.font-size-small {

        /**
         * Font related.
         */

        font-size: 26px;
        line-height: 34px;
        letter-spacing: 1px;

    }

    /**
     * Margin.
     */

    margin-bottom: 20px;

}

/**
 * h2.
 */

h2 {

    /**
     * &.font-size-large.
     */

    &.font-size-large {

        /**
         * Font related.
         */

        font-size: 45px;
        line-height: 1.2;
        letter-spacing: 1px;

    }

    /**
     * Margin.
     */

    margin-bottom: 20px;

}

/**
 * p.
 */

p {

    /**
     * Margin.
     */

    margin-bottom: 15px;

    /**
     * Font related.
     */

    font-size: 15px;
    line-height: 21px;
    font-family: "Montserrat", sans-serif;

}

/**
 * 
 */

.display-none      { display: none; }
.display-block-xs  { @include breakpoint($bs-extra-small-only)  { display: block; } }
.display-block-sm  { @include breakpoint($bs-small-only)        { display: block; } }
.display-block-md  { @include breakpoint($bs-medium-only)       { display: block; } }
.display-block-lg  { @include breakpoint($bs-large-only)        { display: block; } }
.display-block-xl  { @include breakpoint($bs-extra-large-only)  { display: block; } }
.display-block-xxl { @include breakpoint($bs-extra-extra-large-only) { display: block; } }


/**
 * .simple-layouts-item.script.
 */

.simple-layouts-item.script {

    /**
     * p.
     */

    p {

        /**
         * Font related.
         */

        font-family: AdventuresUnlimitedScriptRegular;
        font-size: 70px;
        line-height: 50px;

    }

}

/**
 * @mixin.
 */

@mixin strip-above-image-container {

    /**
     * Position.
     */

    position: relative;

    /**
     * &::after.
     */

    &::after {

        /**
         * Content.
         */

        content: '';

        /**
         * 
         */

        position: absolute;
        top: 0;

        /**
         * Sizing.
         */

        height: 30px;
        width: 100%;

        /**
         * Transform.
         */

        transform: translateY(-100%);

    }

}

/**
 * @mixin.
 */

@mixin strip-below-image-container {

    /**
     * Position.
     */

    position: relative;

    /**
     * &::after.
     */

    &::after {

        /**
         * Content.
         */

        content: '';

        /**
         * 
         */

        position: absolute;
        bottom: 0;

        /**
         * Sizing.
         */

        height: 30px;
        width: 100%;

    }

}
