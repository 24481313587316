/**
 * .common-panel-infoboxesthreeup-wrapper.
 */

.common-panel-infoboxesthreeup-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * .background-image-wrapper.
     */

    .background-image-wrapper {

        /**
         * Pointer.
         */

        pointer-events: none;

        /**
         * Overflow.
         */

        overflow: hidden;

        /**
         * Position.
         */

        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        /**
         * Sizing.
         */

        height: 140%;

        /**
         * .background-image.
         */
    
        .background-image {
    
            /**
             * Position.
             */
    
            position: absolute;
            bottom: 0;
            left: 50%;
    
            /**
             * Transform.
             */

            transform: translateX(550px);

            /**
             * Sizing.
             */
    
            max-height: 100%;
    
        }

    }

    /**
     * .common-panel-infoboxesthreeup-container.
     */

    .common-panel-infoboxesthreeup-container {

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {
            
            /**
             * Padding.
             */

            padding-bottom: 40px;

        }

        /**
         * Z-index.
         */

        z-index: 10;

        /**
         * &.foreground-dark.
         */

        &.foreground-dark {

            /**
             * Font related.
             */

            p {

                /**
                 * Font related.
                 */

                color: $brand-five !important;

            }

        }

        /**
         * .col-box.
         */

        .col-box {

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {
                
                /**
                 * Padding.
                 */

                padding-bottom: 40px;

            }

            /**
             * img.
             */

            img {

                /**
                 * Display.
                 */

                display: none;

            }

            /**
             * .image-and-title-wrapper.
             */

            .image-and-title-wrapper {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;
               
            }

            /**
             * .image-container.
             */

            .image-container {

                /**
                 * Sizing.
                 */

                height: 325px;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Background.
                     */

                    background-position: center top;

                }

            }

            /**
             * .title-wrapper.
             */

            .title-wrapper {

                /**
                 * Padding.
                 */

                padding: 20px;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Font related.
                     */

                    padding-top: 30px;
                    padding-bottom: 30px;

                }

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                justify-content: center;
                align-items: center;

                /**
                 * p.
                 */

                p {

                    /**
                     * Font related.
                     */

                    color: $brand-three;
                    font-family: AdventuresUnlimitedScriptRegular;
                    font-size: 70px;
                    line-height: 40px;
                    text-align: center;

                    /**
                     * Margin.
                     */

                    margin: 0;

                }

            }

            /**
             * .description-wrapper.
             */

            .description-wrapper {

                /**
                 * Padding.
                 */

                padding: 45px 20px 20px 20px;

                /**
                 * Font related.
                 */

                text-align: center;

            }

            /**
             * .simple-layouts.
             */

            .simple-layouts {

                /**
                 * Padding.
                 */
            
                padding-top: 20px;

                /**
                 * Font related.
                 */

                text-align: left;

            }

        }

        /**
         * .to-upper-case.
         */

        .to-upper-case {

            /**
             * Margin.
             */

            margin-bottom: 2px;

            /**
             * Font related.
             */

            letter-spacing: 1px;

        }

        /**
         * .space-below-0.
         */

        .space-below-0 {

            /**
             * 
             */

            p {

                /**
                 * 
                 */

                margin-bottom: 0;

            }

        }

    }

}
