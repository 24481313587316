/**
 * 
 */

$strip-top-height: 30px;
$strip-bottom-height: $strip-top-height;

/**
 * 
 */

$nav-bar-height: 100px;

/**
 * 
 */

$heading-wrapper-left-padding: 30px;