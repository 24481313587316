/**
 * 
 */

@mixin position-flood-fill($position : absolute) {

    /**
     * Position.
     */

    position: $position;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

}
