/**
 * .simple-layouts-item.
 */

.simple-layouts-item {

    /**
     * 
     */

    &.space-below-0  { margin-bottom: 0; }
    &.space-below-1  { margin-bottom: 10px; }
    &.space-below-2  { margin-bottom: 20px; }
    &.space-below-3  { margin-bottom: 30px; }
    &.space-below-4  { margin-bottom: 40px; }
    &.space-below-5  { margin-bottom: 50px; }
    &.space-below-6  { margin-bottom: 60px; }
    &.space-below-7  { margin-bottom: 70px; }
    &.space-below-8  { margin-bottom: 80px; }
    &.space-below-9  { margin-bottom: 90px; }
    &.space-below-10 { margin-bottom: 100px; }

    /**
     * 
     */

    &.space-below--1 { margin-bottom: -10px;}

    /**
     * &.to-upper-case.
     */

    &.to-upper-case {

        /**
         * Font related.
         */

        text-transform: uppercase;

    }

}
