/**
 * .glightbox-container .gslider.
 */

.glightbox-container .gslider {

    /**
     * 
     */

    background: $brand-one !important;

}

/**
 * .glightbox-clean .gslide-media.
 */

.glightbox-clean .gslide-media {

    /**
     * Shadow.
     */

    box-shadow: none !important;

    /**
     * Background.
     */

    background-color: white;

    /**
     * img.
     */

    img {

        /**
         * Background.
         */

        background-color: white;

    }

}

/**
 * 
 */

.glightbox-clean .gclose, 
.glightbox-clean .gnext, 
.glightbox-clean .gprev {

    /**
     * Background.
     */

    background-color: transparent !important;

}

/**
 * .glightbox-clean .gclose.
 */

.glightbox-clean .gclose {

    /**
     * Opacity.
     */

    opacity: 1 !important;

}
