// Remember: do NOT edit this file.

$brand-four: #D8D4D3;

$brand-five: #000000;

$brand-three: #ffffff;

$brand-two: #606B46;

$brand-one: #BCC15A;
