/**
 * Loop variables.
 */

$max: 20;
$i: 0;

/**
 * Loop.
 */

@while $i <= $max {

    /**
     * Positive values.
     */

    .panel-spacing-external-top-#{$i}    { margin-top: 20px * $i;     }
    .panel-spacing-external-bottom-#{$i} { margin-bottom: 20px * $i;  }
    .panel-spacing-internal-top-#{$i}    { padding-top: 20px * $i;    }
    .panel-spacing-internal-bottom-#{$i} { padding-bottom: 20px * $i; }

    /**
     * Negative values.
     */

    .panel-spacing-external-top--#{$i}    { margin-top: -20px * $i;     }
    .panel-spacing-external-bottom--#{$i} { margin-bottom: -20px * $i;  }
    .panel-spacing-internal-top--#{$i}    { padding-top: -20px * $i;    }
    .panel-spacing-internal-bottom--#{$i} { padding-bottom: -20px * $i; }

    /**
     * Increment.
     */

    $i: $i + 1;

}
