/**
 * .common-panel-twocolumntextandvideo-wrapper.
 */

.common-panel-twocolumntextandvideo-wrapper {

    /**
     * Overflow.
     */

    overflow: hidden;

    /**
     * Margin.
     */

    margin-bottom: 20px;

    /**
     * &.residents.
     */

    &.residents {

        /**
         * .graphic.
         */

        .graphic {

            /**
             * Sizing.
             */

            width: 120px;

        }

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Padding.
             */

            padding-top: 60px;

        }

    }

    /**
     * 
     */

    &.coffee, 
    &.country-park-manager, 
    &.mens-shed, 
    &.beekeeper {

        /**
         * .title-and-graphic-wrapper.
         */

        .title-and-graphic-wrapper {

            /**
             * Background.
             */

            // background-color: palegreen;

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            flex-direction: row-reverse;
            align-items: flex-end;
            justify-content: flex-end;

            /**
             * Sizing.
             */

            width: 100%;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Display.
                 */

                display: flex;
                
                /**
                 * Flex.
                 */

                flex-direction: column;
                align-items: center;

            }

            /**
             * .graphic.
             */

            .graphic {

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Margin.
                     */

                    margin-bottom: 20px;

                }

            }

        }

        /**
         * .title.
         */

        .title {

            /**
             * Backgronud.
             */

            // background-color: palegoldenrod;

            /**
             * Margin.
             */

            margin-bottom: 0;
            margin-right: 15px;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Margin.
                 */

                margin-right: 0;

            }

        }

    }

    /**
     * &.coffee.
     */

    &.coffee {

        /**
         * .graphic.
         * 
         */

        .graphic {
        
            /**
             * Sizing.
             */

            height: 130px;

            /**
             * Transform.
             */

            transform: translateY(20px);

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Margin.
                 */

                margin-bottom: 20px;

            }

        }

    }

    /**
     * &.country-park-manager.
     */

    &.country-park-manager {

        /**
         * .graphic.
         */

        .graphic {
        
            /**
             * Sizing.
             */

            height: 60px;

            /**
             * Transform.
             */

            transform: translateX(-10px) translateY(5px) rotate(30deg);

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Margin.
                 */

                margin-bottom: 25px;
                margin-top: 20px;

            }

        }

    }

    /**
     * &.mens-shed.
     */

    &.mens-shed {

        /**
         * .graphic.
         */

        .graphic {
        
            /**
             * Sizing.
             */

            height: 110px;

            /**
             * Transform.
             */

            transform: translateY(10px);

        }

    }

    &.beekeeper {

        /**
         * .graphic.
         */

        .graphic {
        
            /**
             * Sizing.
             */

            height: 90px;

            /**
             * Transform.
             */

            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Margin.
                 */

                margin-bottom: 0 !important;

            }

        }

    }

    /**
     * .common-panel-twocolumntextandvideo-container.
     */

    .common-panel-twocolumntextandvideo-container {

        /**
         * .row-main.
         */

        .row-main {

            /**
             * &.column-reverse.
             */

            &.column-reverse {

                /**
                 * Flex.
                 */

                flex-direction: row-reverse;

            }

            /**
             * .col-text.
             */

            .col-text {

                /**
                 * Backgronud.
                 */

                // background-color: paleturquoise;

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * 
                 */

                flex-direction: column;
                justify-content: center;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Font related.
                     */

                    text-align: center;

                    /**
                     * Padding.
                     */

                    padding-bottom: 15px;

                }

                /**
                 * .title-and-graphic-wrapper.
                 */

                .title-and-graphic-wrapper {

                    /**
                     * Margin.
                     */

                    margin-bottom: 0px;

                    /**
                     * .title.
                     */

                    .title {

                        /**
                         * Margin.
                         */

                        margin-bottom: 5px;

                    }

                }

                /**
                 * .subtitle.
                 */

                .subtitle {

                    /**
                     * Font related.
                     */

                    font-family: AdventuresUnlimitedScriptRegular;
                    font-size: 65px;
                    line-height: 50px;

                }

                /**
                 * 
                 */

                img.graphic {

                    /**
                     * Sizing.
                     */

                    max-width: 200px;

                }

            }

            /**
             * .col-video.
             */

            .col-video {

                /**
                 * Background.
                 */

                // background-color: palevioletred;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Margin.
                     */

                    margin-bottom: 25px;

                }

                /**
                 * .video-wrapper.
                 */

                .video-wrapper {

                    /**
                     * Sizing.
                     */

                    min-width: 100%;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Sizing.
                         */

                        max-width: 100% !important;

                    }                    

                }

            }

        }

    }

}
