/**
 * .common-panel-camelliacourtapartments-wrapper.
 */

.common-panel-camelliacourtapartments-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * Overflow.
     */

    overflow: hidden;

    /**
     * .common-panel-camelliacourtapartments-container.
     */

    .common-panel-camelliacourtapartments-container {

        /**
         * .row-heading.
         */

        .row-heading {

            /**
             * Font related.
             */

            text-align: center;

        }

        /**
         * .title.
         */

        .title {

            /**
             * Font related.
             */

            font-size: 30px;
            line-height: 34px;
            
        }

        /**
         * .row-image.
         */

        .row-image {

            /**
             * Position.
             */

            position: relative;

            /**
             * Margin.
             */

            margin-bottom: 40px;

            /**
             * .graphic.
             */

            .graphic {

                /**
                 * Position.
                 */

                position: absolute;
                top: 0;
                right: 0;

                /**
                 * Sizing.
                 */

                max-width: 230px;

                /**
                 * Transform.
                 */

                transform: translateX(50%) translateY(-40%);

            }

            /**
             * .image-container.
             */

            .image-container {

                /**
                 * Display.
                 */

                display: block;

                /**
                 * Margin.
                 */

                margin-top: 20px;

                /**
                 * Sizing.
                 */

                min-height: 800px;
                width: 100%;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Sizing.
                     */

                    min-height: 400px;

                }

                /**
                 * img.
                 */

                img {

                    /**
                     * Display.
                     */

                    display: none;

                }

                /**
                 * @include.
                 */

                @include strip-below-image-container;

                /**
                 * &::after.
                 */

                &::after {

                    /**
                     * Background.
                     */

                    background-color: #CD7C9F;

                }

            }
            
        }

        /**
         * .apartments-wrapper.
         */

        .apartments-wrapper {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            justify-content: center;

            /**
             * hr.
             */

            hr {

                /**
                 * Sizing.
                 */

                width: 100%;

                /**
                 * Sizing.
                 */

                height: 0;

                /**
                 * Margin.
                 */

                margin: 0;

                /**
                 * Border.
                 */

                border-top: 0;

            }

            /**
             * .block-apartment
             */

            .block-apartment {

                /**
                 * Margin.
                 */

                margin-bottom: 30px;

                /**
                 * Font related.
                 */

                text-decoration: none;

                /**
                 * Display.
                 */

                display: flex;

                flex-direction: column;



            }

            /**
             * .image-container.
             */

            .image-container {

                /**
                 * Sizing.
                 */

                height: 300px;

            }

            /**
             * img.
             */

            img {

                /**
                 * Display.
                 */

                display: none;

            }

            /**
             * svg.
             */

            svg {

                /**
                 * Sizing.
                 */

                width: 50px;

                /**
                 * path.
                 */

                path {

                    /**
                     * Fill.
                     */

                    fill: white !important;

                }

            }

            /**
             * .text-wrapper.
             */

            .text-wrapper {

                /**
                 * Padding.
                 */

                padding: 20px 30px 20px 30px;

                /**
                 * Disply.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-grow: 1;
                justify-content: space-between;

                /**
                 * Background.
                 */

                background-color: #CD7C9F;

                /**
                 * p.
                 */

                p {

                    /**
                     * Margin.
                     */

                    margin-bottom: 0;

                    /**
                     * Font related.
                     */

                    font-size: 20px;
                    line-height: 24px;
                    color: white;
                    text-transform: uppercase;

                    /**
                     * span.title.
                     */

                    span.title {

                        /**
                         * Sizing.
                         */

                        font-weight: bold;
                        font-size: inherit;
                        line-height: inherit;

                    }

                    /**
                     * span.plot-numbers.
                     */

                    span.plot-numbers {

                        /**
                         * Font related.
                         */
                        
                        font-size: 15px;

                    }

                }

            }

        }

    }

}
