/**
 * .simple-layouts-address.
 */

.simple-layouts-address {

    /**
     * Font related.
     */

    text-align: left;

    /**
     * List.
     */

    list-style: inside;

    /**
     * li.
     */

    li {

        /**
         * Margin.
         */

        margin-bottom: 0px;

        /**
         * Font related.
         */

        font-size: 18px;
        line-height: 22px;

    }

}
