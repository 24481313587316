/**
 * .common-panel-videoinset-wrapper.
 */

.common-panel-videoinset-wrapper {

    /**
     * .common-panel-videoinset-container.
     */

    .common-panel-videoinset-container {

        /**
         * .inset.
         */

        .inset {

            /**
             * Padding.
             */

            padding: 70px;

            /**
             * @include.
             */

            @include breakpoint($bs-extra-small) {

                /**
                 * Padding.
                 */

                padding: 10px;

            }

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Padding.
                 */

                padding: 10px;

            }

        }            

    }

}
