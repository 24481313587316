/**
 * .common-panel-housesatpinewoodgreeneast-wrapper.
 */

.common-panel-housesatpinewoodgreeneast-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * @include.
     */

    @include strip-below-image-container;

    /**
     * &::after.
     */

    &::after {

        /**
         * Background.
         */

        background-color: $brand-one;

    }

    /**
     * .common-panel-housesatpinewoodgreeneast-container.
     */

    .common-panel-housesatpinewoodgreeneast-container {

        /**
         * Position.
         */

        position: relative;

        /**
         * .row-heading.
         */

        .row-heading {

            /**
             * Font related.
             */

            text-align: center;

            /**
             * Margin.
             */

            margin-bottom: 30px;

        }

        /**
         * .graphic.
         */

        .graphic {

            /**
             * Position.
             */

            position: absolute;
            bottom: 10px;

            /**
             * Sizing.
             */

            width: 250px;

            /**
             * Z-index.
             */

            z-index: 10;

        }

        /**
         * .houses-wrapper.
         */

        .houses-wrapper {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            justify-content: center;

            /**
             * .block-house
             */

            .block-house {

                /**
                 * Margin.
                 */

                margin-bottom: 30px;

                /**
                 * Font related.
                 */

                text-decoration: none;

            }

            /**
             * .image-container.
             */

            .image-container {

                /**
                 * Sizing.
                 */

                height: 300px;

            }

            /**
             * img.
             */

            img {

                /**
                 * Display.
                 */

                display: none;

            }

            /**
             * svg.
             */

            svg {

                /**
                 * Sizing.
                 */

                width: 50px;

                /**
                 * path.
                 */

                path {

                    /**
                     * Fill.
                     */

                    fill: white !important;

                }

            }

            /**
             * .text-wrapper.
             */

            .text-wrapper {

                /**
                 * Padding.
                 */

                padding: 20px 30px 20px 30px;

                /**
                 * Disply.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                justify-content: space-between;

                /**
                 * p.
                 */

                p {

                    /**
                     * Margin.
                     */

                    margin-bottom: 0;

                    /**
                     * Font related.
                     */

                    font-size: 20px;
                    line-height: 24px;
                    color: white;
                    text-transform: uppercase;

                    /**
                     * span.
                     */

                    span {

                        /**
                         * Sizing.
                         */

                        font-weight: bold;

                    }



                }


            }

        }

    }

}
