/**
 * @font-face.
 */

@font-face {

    /**
     * Font related.
     */

    font-family: "AdventuresUnlimitedScriptRegular";

    /**
     * Src.
     */

    src: url('/assets/web-fonts/AdventuresUnlimitedScriptRegular/font.woff2') format('woff2'), url('/assets/web-fonts/AdventuresUnlimitedScriptRegular/font.woff') format('woff');

}
