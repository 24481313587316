/**
 * @mixin.
 */

@mixin background-extension-right() {

    /**
     * @include.
     */

    @include background-extension();

    /**
     * &:after.
     */

    &:after {

        /**
         * Position.
         */

        left: 0;
        right: -2000px;

    }

}
