/**
 * .common-panel-blocksetd-wrapper.
 */

 .common-panel-blocksetd-wrapper {

    /**
     * Overlfow.
     */

    overflow: hidden;

    /**
     * .common-panel-blocksetd-container.
     */

    .common-panel-blocksetd-container {

        /**
         * Background.
         */

        // background-color: palegoldenrod;

        /**
         * .col-grid.
         */

        .col-grid {

            /**
             * Display.
             */
        
            display: grid;

            /**
             * Grid.
             */

            grid-template-columns: 4fr 8fr;
            grid-template-rows: 8fr 5fr;
            grid-template-areas: 
                'intro      image'
                'fancy-text image';

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Grid.
                 */
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
                grid-template-areas: 
                'intro'
                'image'
                'fancy-text ';

                /**
                 * Sizing.
                 */

                min-height: auto;

            }

            /**
             * Sizing.
             */

            min-height: 900px;
            width: 100%;

            /**
             * .intro.
             */

            .intro {

                /**
                 * Background.
                 */

                background-color: $brand-two;

                /**
                 * Padding.
                 */

                padding-right: 40px;

                /**
                 * Grid.
                 */

                grid-area: intro;

                /**
                 * @include.
                 */

                @include background-extension-left;

                /**
                 * &::after.
                 */

                &::after {

                    /**
                     * Sizing.
                     */

                    right: -10px;

                }

                /**
                 * Display.
                 */
            
                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                /**
                 * Font related.
                 */

                color: white;

                /**
                 * > *.
                 */

                > * {

                    /**
                     * Position,
                     */

                    position: relative;


                    /**
                     * Z-index.
                     */

                    z-index: 1;

                }

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Padding.
                     */

                    padding-top: 50px;
                    padding-bottom: 50px;
                    padding-right: 0;

                    /**
                     * Font related.
                     */

                    text-align: center;

                    /**
                     * Flex.
                     */

                    align-items: center;

                    /**
                     * &::after.
                     */

                    &::after {

                        /**
                         * Transform.
                         */

                        transform: translateX(50%);

                    }

                }

            }
            
            /**
             * div.image.
             */

            div.image {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * Background.
                 */

                // background-color: rgba( crimson, 0.6 );

                /**
                 * Grid.
                 */

                grid-area: image;

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;
                align-items: flex-start;

                /**
                 * img.
                 */

                img {

                    /**
                     * Display.
                     */

                    display: none;

                }

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Sizing.
                     */

                    height: 400px;

                }

                /**
                 * .image-container.
                 */

                .image-container {

                    /**
                     * Flex.
                     */

                    flex-grow: 1;

                    /**
                     * Sizing.
                     */

                    width: 160%;

                    /**
                     * Background.
                     */

                    background-position: left top;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Sizing.
                         */

                        height: 100%;
                        width: 100vw;

                        /**
                         * Position.
                         */

                        position: absolute;
                        left: 50%;

                        /**
                         * Transform.
                         */

                        transform: translateX(-50%);

                    }

                }

            }

            /**
             * .fancy-text.
             */

            .fancy-text  {

                /**
                 * Background.
                 */

                // background-color: rgba( orange, 0.6 );

                /**
                 * Grid.
                 */

                grid-area: fancy-text;

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;
                justify-content: center;

                /**
                 * Flex.
                 */

                flex-grow: 2;

                /**
                 * Padding.
                 */

                padding-top: 20px;
                padding-bottom: 10px;
                padding-right: 10px;

                /**
                 * Background.
                 */

                background-color: rgb(236, 234, 233);

                /**
                 * div.
                 */

                div {

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Z-index.
                     */

                    z-index: 1;

                }
                
                /**
                 * p.
                 */
                
                p {
                    
                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Z-index.
                     */

                    z-index: 1;

                    /**
                     * Font related.
                     */

                    font-family: AdventuresUnlimitedScriptRegular;
                    font-size: 70px;
                    line-height: 50px;

                    /**
                     * @include.
                     */

                    @include breakpoint($bs-extra-small) {

                        /**
                         * Padding.
                         */

                        padding-left: 20px;

                    }

                }

                /**
                 * @include.
                 */

                @include background-extension-left;

                /**
                 * &::after.
                 */

                &::after {

                    /**
                     * Sizing.
                     */

                    right: -10px;

                }

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * &::after.
                     */

                    &::after {

                        /**
                         * Transform.
                         */

                        transform: translateX(50%);

                    }

                }

            }

        }

    }

}
