/**
 * footer.
 */

footer {

    /**
     * Sizing.
     */

    // height: 270px;

    /**
     * Background.
     */

    background-color: $brand-one;

    /**
     * Padding.
     */

    padding-top: 60px;
    padding-bottom: 60px;

    /**
     * .col-logo.
     */

    .col-logo {

        /**
         * Font related.
         */

        text-align: left;

    }

    /**
     * .col-developer-logo.
     */

    .col-developer-logo {

        /**
         * Font related.
         */

        text-align: right;

        /**
         * img.
         */

        img {

            /**
             * Sizing.
             */

            height: 70px;
            max-width: 100%;

        }

    }

    /**
     * img.
     */

    img {

        /**
         * Sizing.
         */

        height: 75px;

    }

}
