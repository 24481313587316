/**
 * .common-panel-contactusextras-wrapper.
 */

.common-panel-contactusextras-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * Overflow.
     */

    overflow-x: clip;

    /**
     * .graphic.
     */

    .graphic {

        /**
         * Position.
         */

        position: absolute;
        left: 60%;
        bottom: 0;

        /**
         * Opacity.
         */

        opacity: 0.2;

        /**
         * Z-index.
         */

        z-index: 0;

        /**
         * @include.
         */

        @include breakpoint($bs-extra-small) {

            /**
             * Position.
             */

            left: 10%;

            /**
             * Opacity.
             */

            opacity: 0.1;

        }

    }

    /**
     * .common-panel-contactusextras-container.
     */

    .common-panel-contactusextras-container {

        /**
         * .row-map.
         */

        .row-map {

            /**
             * .col-map.
             */

            .col-map {

                /**
                 * img.
                 */

                img {

                    /**
                     * Sizing.
                     */

                    max-width: 100%;

                    /**
                     * Margin.
                     */

                    margin-bottom: 30px;

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Z-index.
                     */

                    z-index: 1;

                }


            }

        }

        /**
         * .col-main.
         */

        .col-main {

            /**
             * Flex.
             */
    
            display: flex;
    
            /**
             * Flex.
             */
    
            flex-direction: column;
            justify-content: center;
            align-items: center;

            /**
             * Font related.
             */

            text-align: center;

            /**
             * p.email, p.website, p.address.
             */

            p.email, p.website, p.address {

                /**
                 * Font related.
                 */

                font-size: 19px;
                font-weight: 400;

                /**
                 * Margin.
                 */

                margin-bottom: 20px;

                /**
                 * a.
                 */

                a {

                    /**
                     * Font related.
                     */

                    color: $brand-five;
                    font-weight: 500;

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * Font related.
                         */

                        text-decoration: none;
                        color: $brand-two;

                    }

                    /**
                     * @include.
                     */

                    @include breakpoint($bs-extra-small) {

                        /**
                         * Display.
                         */

                        display: block;

                    }

                }

            }

            /**
             * p.address.
             */

            p.address {

                /**
                 * Margin.
                 */

                margin-bottom: 40px;

                /**
                 * span.
                 */

                span {

                    /**
                     * Font related.
                     */

                    font-weight: 500;

                }

            }

            /**
             * p.disclaimer.
             */

            p.disclaimer {
                
                /**
                 * Font related.
                 */

                font-size: 13px;


            }

        }

    }

}
