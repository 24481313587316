/**
 * .common-panel-contactus-wrapper.
 */

.common-panel-contactus-wrapper {

    /**
     * Sizing.
     */

    // height: calc(100vh - $nav-bar-height) !important;
    height: calc(100dvh - 100px);

    /**
     * Position.
     */

    position: relative;

    /**
     * .common-panel-contactus-container.
     */

    .common-panel-contactus-container {

        /**
         * Position.
         */

        position: relative;

        /**
         * Sizing.
         */

        overflow: hidden;

        /**
         * Sizing.
         */

        // height: calc(100vh - $nav-bar-height) !important;
        height: 100%;
        width: 100%;
        max-width: 100%;

        /**
         * Padding.
         */

        padding: 0;

        /**
         * .row.
         */

        .row {

            /**
             * Sizing.
             */

            height: 100%;

        }

        /**
         * .col-main.
         */

        .col-main {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            flex-direction: column;
            align-items: center;
            justify-content: center;

            /**
             * .graphic.
             */

            .graphic {

                /**
                 * Sizing.
                 */

                height: 210px;

                /**
                 * Margin.
                 */
            
                margin-bottom: 50px;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Sizing.
                     */

                    height: 200px;

                }

                /**
                 * @include.
                 */

                @include breakpoint($bs-extra-small) {

                    /**
                     * Sizing.
                     */

                    height: 140px;

                }

            }

            /**
             * .telephone.
             */

            .telephone {

                /**
                 * Font related.
                 */

                text-transform: uppercase;
                font-family: "Montserrat", sans-serif;
                font-optical-sizing: auto;
                font-weight: 500;
                letter-spacing: 2px;
                font-size: 50px;
                line-height: 60px;

                /**
                 * @include.
                 */

                 @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Font related.
                     */

                    font-size: 50px;
                    line-height: 60px;

                }

                /**
                 * @include.
                 */

                @include breakpoint($bs-extra-small) {

                    /**
                     * Font related.
                     */

                    text-align: center;
                    font-size: 30px;
                    line-height: 40px;

                }
                

            }

            /**
             * img.arrow.
             */

            img.arrow {

                /**
                 * Display.
                 */

                display: none;

            }

            /**
             * svg.arrow.
             */

            svg.arrow {

                /**
                 * Position.
                 */

                position: absolute;
                bottom: 20px;

                /**
                 * Sizing.
                 */

                width: 60px;
                max-height: 50px;

                /**
                 * Z-index.
                 */

                z-index: 2;

                /**
                 * path.
                 */

                path {

                    /**
                     * Fill.
                     */

                    fill: $brand-two;

                }

            }

        }

        /**
         * &::before.
         */

        &::before {

            /**
             * Content.
             */

            content: '';

            /**
             * 
             */

            position: absolute;
            top: 0;

            /**
             * Sizing.
             */

            height: 30px;
            width: 100%;

            /**
             * Background.
             */

            background-color: $brand-one;

            /**
             * Z-index.
             */

            z-index: 10;

        }

        /**
         * .heading-wrapper.
         */

        .heading-wrapper {

            /**
             * Padding.
             */

            padding-bottom: 20px;

            /**
             * h1.
             */

            h1 {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * Font related.
                 */

                color: black;
                font-weight: 400;
                font-size: 50px;
                line-height: 60px;
                letter-spacing: 6px;

                /**
                 * Z-index.
                 */

                z-index: 2;

                /**
                 * Padding.
                 */

                padding-bottom: 0px;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {
                    
                    /**
                     * Font related.
                     */

                    font-size: 40px;
                    line-height: 50px;
                    letter-spacing: 6px;                   

                    /**
                     * Padding.
                     */

                    padding-left: 30px;
                    padding-right: 30px;

                }

                /**
                 * @include.
                 */

                @include breakpoint($bs-extra-small) {

                    /**
                     * Font related.
                     */

                    font-size: 30px;
                    line-height: 1.2;
                    letter-spacing: 3px;

                }

            }

            /**
             * Font related.
             */

            text-align: center;

            /**
             * img, svg.
             */

            img, svg {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * Sizing.
                 */

                width: 60px;

                /**
                 * Z-index.
                 */

                z-index: 2;

            }

        }

    }

}
