/**
 * .common-panel-houseandapartmentsblocksselector-wrapper.
 */

.common-panel-houseandapartmentsblocksselector-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * Overlfow.
     */

    overflow: hidden;

    /**
     * .background-image-wrapper.
     */

    .background-image-wrapper {

        /**
         * Position.
         */

        position: absolute;
        right: 0;
        bottom: 0;

        /**
         * Z-index.
         */

        z-index: 2;

        /**
         * Transform.
         */

        transform: translateX(140px);

        /**
         * img.
         */

        img {

            /**
             * Sizing.
             */

            width: 400px;

        }

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * img.
             */

            img {

                /**
                 * Sizing.
                 */

                width: 250px;

            }

            /**
             * Transform.
             */

            transform: translateX(100px) translateY(0);

        }

    }

    /**
     * .common-panel-houseandapartmentsblocksselector-container.
     */

    .common-panel-houseandapartmentsblocksselector-container {

        /**
         * a.
         */

        a {

            /**
             * 
             */

        }

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Font related.
             */
        
            text-align: center;

            /**
             * Padding.
             */

            padding-bottom: 0px;

        }

        /**
         * .col-block.
         */

        .col-block {

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Margin.
                 */
            
                margin-bottom: 60px;

            }

        }

        /**
         * .title.
         */

        .title {

            /**
             * Font related.
             */

            font-size: 30px;
            line-height: 40px;
            text-transform: uppercase;
            font-family: "Montserrat", sans-serif;
            font-optical-sizing: auto;
            font-weight: 500;
            letter-spacing: 2px;
            
        }

        /**
         * .description-wrapper.
         */

        .description-wrapper {

            /**
             * Padding.
             */

            // padding-bottom: 20px;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Padding.
                 */
            
                padding-bottom: 10px;

            }

        }

        /**
         * .image-container.
         */

        .image-container {

            /**
             * Display.
             */

            display: block;

            /**
             * Margin.
             */

            margin-top: 20px;

            /**
             * Sizing.
             */

            min-height: 400px;
            width: 100%;

            /**
             * img.
             */

            img {

                /**
                 * Display.
                 */

                display: none;

            }

            /**
             * @include.
             */

            @include strip-below-image-container;

            /**
             * &::after.
             */

            &::after {

                /**
                 * Background.
                 */

                background-color: $brand-one;

                /**
                 * Position.
                 */

                left: 0;

            }

        }

    }

}
