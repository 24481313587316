/**
 * .common-panel-traveltimelines-wrapper.
 */

.common-panel-traveltimelines-wrapper {

    /**
     * .common-panel-traveltimelines-container.
     */

    .common-panel-traveltimelines-container {

        /**
         * Flex.
         */

        > .row {

            /**
             * Flex.
             */

            justify-content: center;

            /**
             * @include.
             */

            @include breakpoint($b-df-tablet) {

                /**
                 * Display.
                 */

                justify-content: space-around;

            }

        }

        /**
         * .col-transport-mode.
         */

        .col-transport-mode {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            flex-direction: column;
            align-items: center;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Display.
                 */

                display: block;

            }

            /**
             * .inset.
             */

            .inset {

                /**
                 * 
                 */

                display: flex;
                flex-direction: column;
                align-items: center;

                /**
                 * @include.
                 */

                 @include breakpoint($b-df-mobile-landscape) {
                    
                    /**
                     * Margin.
                     */

                    margin-bottom: 10px;

                }

            }

            /**
             * .graphic-wrapper.
             */

            .graphic-wrapper {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * Sizing.
                 */

                width: 170px;
                height: 170px;

                /**
                 * Margin.
                 */

                margin-bottom: 30px;

                /**
                 * &::after.
                 */

                &::after {

                    /**
                     * Content.
                     */

                    content: '';

                    /**
                     * Sizing.
                     */

                    width: 100%;
                    height: 100%;

                    /**
                     * Background.
                     */

                    background-color: $brand-one;

                    /**
                     * Border.
                     */

                    border-radius: 100%;

                    /**
                     * Position.
                     */

                    position: absolute;
                    top: 0;
                    left: 0;

                    /**
                     * Z-index.
                     */

                    z-index: 1;

                }

                /**
                 * img.
                 */
            
                img {

                    /**
                     * Position.
                     */

                    position: absolute;
                    left: 50%;
                    bottom: 0;

                    /**
                     * Transform.
                     */

                    transform: translateX(-5%) translateY(-10px);

                    /**
                     * Z-index.
                     */

                    z-index: 2;

                    /**
                     * Sizing.
                     */

                    width: 190px;                    

                }

            }

            /**
             * .timeline.
             */

            .timeline {

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {
                    
                    /**
                     * Margin.
                     */

                    margin-bottom: 00px;
                    
                }

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {
                    
                    /**
                     * Sizing.
                     */

                    width: 100%;

                }                
                
            }

            /**
             * .heading.
             */

            .heading {

                /**
                 * Font related.
                 */

                font-size: 20px;
                line-height: 22px;

            }

            /**
             * .items-wrapper.
             */

            .items-wrapper {
                
                /**
                 * Margin.
                 */

                margin-bottom: 10px;

                /**
                 * Background.
                 */

                // background-color: orange;

                /**
                 * .item-wrapper.
                 */

                .item-wrapper {

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Padding.
                     */

                    padding-bottom: 20px;
                    padding-left: 0;

                    /**
                     * &:last-child.
                     */

                    &:last-child {

                        /**
                         * &::after.
                         */

                        &::after {

                            /**
                             * Content.
                             */

                            content: none;

                        }

                    }

                    /**
                     * &::after.
                     */

                    &::after {

                        /**
                         * Content.
                         */

                        content: '';

                        /**
                         * Sizing.
                         */

                        width: 2px;
                        height: 100%;

                        /**
                         * Position.
                         */
                    
                        position: absolute;
                        top: 6px;
                        left: 8px;

                        /**
                         * Background.
                         */

                        background-color: $brand-one;

                    }

                    /**
                     * p.
                     */

                    p {

                        /**
                         * Margin.
                         */

                        margin-bottom: 0;

                        /**
                         * Padding.
                         */

                        padding-left: 30px;

                    }

                    /**
                     * p.title.
                     */

                    p.title {

                        /**
                         * Position.
                         */

                        position: relative;

                        /**
                         * Font related.
                         */

                        font-weight: bold;

                        /**
                         * &::after.
                         */

                        &::after {

                            /**
                             * Content.
                             */

                            content: '';

                            /**
                             * Sizing.
                             */

                            width: 17px;
                            height: 17px;

                            /**
                             * Border.
                             */

                            border-radius: 100%;

                            /**
                             * Position.
                             */
                        
                            position: absolute;
                            top: 50%;
                            left: 0;

                            /**
                             * Transform.
                             */

                            transform: translateY(-50%);

                            /**
                             * Background.
                             */

                            background-color: $brand-one;

                        }

                    }

                }

            }

        }

    }

}
