/**
 * .nav-hamburger.
 */

.nav-hamburger {

    /**
     * Background.
     */

    background-color: transparent !important;

    /**
     * Border.
     */

    border: 0;

    /**
     * Outline.
     */

    outline: none;

    /**
     * Sizing.
     */

    width: 35px;
    height: 38px;

    /**
     * Position.
     */

    position: relative;

    /**
     * Transform.
     */

    transform: rotate(0deg);

    /**
     * Cursor.
     */

    cursor: pointer;

    /**
     * Outline.
     */

    outline: none !important;

    /** 
     * span.
     */

    span {

        /**
         * Display.
         */

        display: block;

        /**
         * Position.
         */

        position: absolute;
        left: 0;

        /**
         * Sizing.
         */

        height: 3px;
        width: 100%;
        
        /**
         * Background.
         */

        background: $brand-three;

        /**
         * Border.
         */

        border-radius: 9px;

        /**
         * Opacity.
         */

        opacity: 1;
        
        /**
         * Transform.
         */

        transform: rotate(0deg);

        /**
         * Transition.
         */

        transition: .25s ease-in-out;

    }

    /**
     * span:nth-child(1).
     */

    span:nth-child(1) {
        top: 10px;
    }

    /**
     * 
     */

    span:nth-child(2),
    span:nth-child(3) {
        top: 18px;
    }

    /**
     * 
     */

    span:nth-child(4) {
        top: 26px;
    }

    /**
     * 
     */
        
    &.open span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    /**
     * 
     */

    &.open span:nth-child(2) {
        transform: rotate(45deg);
    }

    /**
     * 
     */

    &.open span:nth-child(3) {
        transform: rotate(-45deg);
    }

    /**
     * 
     */

    &.open span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    /**
     * &.disable.
     */

    &.disable {

        /**
         * Opacity.
         */

        opacity: 0.2;

        /**
         * 
         */

        pointer-events: none;

    }

}
