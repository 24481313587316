/**
 * @mixin.
 */

@mixin background-extension() {

    /**
     * Position.
     */

    position: relative;

    /**
     * &:after.
     */

    &:after {

        /**
         * Content.
         */

        content: '';

        /**
         * Position.
         */

        position: absolute;
        top: 0;
        right: 0;   /** Override by _background-extension-[left][right].scss */
        left: 0;    /** Override by _background-extension-[left][right].scss */
        bottom: 0;

        /**
         * Background.
         */
    
        background-color: inherit;

        /**
         * Z-index.
         */

        z-index: 0;

    }

}
