/**
 * 
 */

$rowHeight: 800px;

/**
 * .housetypes-panel-hero-wrapper.
 */

.housetypes-panel-hero-wrapper {

    /**
     * Overflow.
     */

    overflow: hidden;

    /**
     * .housetypes-panel-hero-container.
     */

    .housetypes-panel-hero-container {

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Sizing.
             */

            max-width: 100%;

        }

        /**
         * > .row.
         */

        > .row {

            /**
             * Sizing.
             */
        
            height: $rowHeight;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Sizing.
                 */

                height: auto;

            }

            /**
             * @include.
             */

            @include breakpoint($b-tablet-only) {

                /**
                 * Sizing.
                 */

                height: 700px;

            }

        }

        /**
         * .col-image.
         */

        .col-image {

            /**
             * .inset.
             */

            .inset {

                /**
                 * Sizing.
                 */

                height: 100%;

            }

            /**
             * .image-container.
             */

            .image-container {

                /**
                 * Sizing.
                 */

                height: 100%;
                width: 100%;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Sizing.
                     */

                    height: 450px;
                    max-width: 100%;

                }

            }

            /**
             * img.
             */

            img {

                /**
                 * Display.
                 */

                display: none;

            }

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Padding.
                 */

                padding-right: 0;
                padding-left: 0;

            }

        }

        /**
         * .col-text.
         */

        .col-text {

            /**
             * @include.
             */

            @include background-extension-right;

            /**
             * &::after.
             */

            &::after {

                /**
                 * Transform.
                 */

                transform: translateX(-15px);

            }

            /**
             * .inset.
             */
            
            .inset {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * Padding.
                 */

                padding-left: 10px;
                padding-top: 100px;

                /**
                 * @include.
                 */

                @include breakpoint($b-tablet-only) {

                    /**
                     * Padding.
                     */

                    padding-top: 30px;

                }

                /**
                 * Sizing.
                 */

                height: 100%;

                /**
                 * Font related.
                 */

                color: white;

                /**
                 * Z-index.
                 */

                z-index: 1;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Padding.
                     */

                    padding-top: 40px;
                    padding-bottom: 40px;

                    /**
                     * Sizing.
                     */

                    height: auto;

                }

            }

            /**
             * Sizing.
             */

            /**
             * .graphic.
             */

            .graphic {

                /**
                 * Sizing.
                 */

                width: 120px;

                /**
                 * Margin.
                 */

                margin-bottom: 20px;

            }
    
            /**
             * .heading.
             */
            
            .heading {

                /**
                 * Margin.
                 */

                margin-bottom: 6px;

                /**
                 * Font related.
                 */

                font-size: 40px;

            }

            /**
             * .bedrooms.
             */

            .bedrooms {

                /**
                 * Font related.
                 */

                text-transform: uppercase;
                font-size: 28px;
                line-height: 32px;
                letter-spacing: 2px;

                /**
                 * Margin.
                 */

                margin-bottom: 40px;

            }

            /**
             * .houses-word.
             */

            .houses-word {

                /**
                 * Font related.
                 */

                font-size: 26px;
                line-height: 30px;
                text-transform: uppercase;

                /**
                 * Margin.
                 */

                margin-bottom: 20px;

            }

            /**
             * .plot-list.
             */

            .plot-list {

                /**
                 * Font related.
                 */

                font-size: 20px;
                line-height: 26px;

            }

            /**
             * .handed.
             */

            .handed {

                /**
                 * Font related.
                 */

                font-size: 12px;
                line-height: 16px;

            }

        }


    }

}
