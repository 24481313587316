/**
 * .common-panel-twocolumntextandimage-wrapper.
 */

.common-panel-twocolumntextandimage-wrapper {

    /**
     * .common-panel-twocolumntextandimage-container.
     */

    .common-panel-twocolumntextandimage-container {

        /**
         * .row-main.
         */

        .row-main {

            /**
             * &.column-reverse.
             */

            &.column-reverse {

                /**
                 * Flex.
                 */

                flex-direction: row-reverse;

            }

        }

        /**
         * .berkeley-difference-staggered.
         */

        &.berkeley-difference-staggered {

            /**
             * .image-container.
             */

            .image-container {

                /**
                 * Sizing.
                 */

                min-height: 500px

            }

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {
                
                /**
                 * Padding.
                 */

                padding-bottom: 30px;

            }

        }

        /**
         * .col-text.
         */

        .col-text {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            flex-direction: column;
            justify-content: center;

            /**
             * 
             */

        }

        /**
         * .col-image.
         */

        .col-image {

            /**
             * .image-container.
             */

            .image-container {

                /**
                 * &.strip-below.
                 */

                &.strip-below {

                    /**
                     * @include.
                     */

                    @include strip-below-image-container;

                    /**
                     * &::after.
                     */

                    &::after {

                        /**
                         * Background.
                         */

                        background-color: $brand-one;

                    }

                }

                /**
                 * Sizing.
                 */

                height: 100%;
                width: 100%;

                /**
                 * img.
                 */

                img {

                    /**
                     * Display.
                     */

                    display: none;

                }

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {
                    
                    /**
                     * Margin.
                     */

                    margin-top: 30px;

                    /**
                     * Sizing.
                     */

                    height: 500px;

                }

            }

        }

        /**
         * .space-below-0.
         */

        .space-below-0 {

            /**
             * 
             */

            p {

                /**
                 * 
                 */

                margin-bottom: 0;

            }

        }


    }

}
