/**
 * .video-wrapper.
 */

.video-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * Font related.
     */

    line-height: 0;

    /**
     * .flatvideo-embed-wrapper.
     */

    .flatvideo-embed-wrapper {

        /**
         * Position.
         */

        position: relative;

        /**
         * Margin.
         */

        margin: 0 auto; 

        /**
         * Background.
         */

        background-color: rgba( #ececec, 0.3 );

        /**
         * Sizing. 
         */

        max-width: 100%;
        
        /**
         * .flatvideo-embed-container.
         */

        .flatvideo-embed-container {                
            position: relative; 
            padding-bottom: 56.25%; 
            height: 0; 
            overflow: hidden; 
            max-width: 100%;
        }
        
        /**
         * 
         */

        .flatvideo-embed-container iframe, 
        .flatvideo-embed-container object, 
        .flatvideo-embed-container embed { 
            position: absolute; 
            top: 0; left: 0; 
            width: 100%; 
            height: 100%; 
        }

    }

}
