/**
 * .common-panel-herotypea-wrapper.
 */

.common-panel-herotypea-wrapper {

    /**
     * Sizing.
     */

    // height: calc(100vh - $nav-bar-height);
    // height: 50vh;
    height: calc(100dvh - 100px);

    /**
     * Position.
     */

    position: relative;

    /**
     * &.strip-below.
     */

    &.strip-below {

        /**
         * @include.
         */

        @include strip-below-image-container;

        /**
         * &::after.
         */

        &::after {

            /**
             * Background.
             */

            background-color: $brand-one;

            /**
             * Transform.
             */

            transform: translateY(100%);

        }

    }

    /**
     * &.apply-blur.
     */

    &.apply-blur {

        /**
         * Filter.
         */

        filter: blur(10px);

    }

    // /**
    //  * .note.
    //  */

    // .note {

    //     /**
    //      * 
    //      */

    //     @include position-transform-center;

    //     /**
    //      * 
    //      */

    //     background-color: magenta !important;

    //     /**
    //      * Padding.
    //      */

    //     padding: 20px;

    //     /**
    //      * p.
    //      */

    //     p {

    //         /**
    //          * Marign.
    //          */

    //         margin-bottom: 0;

    //         /**
    //          * Font related.
    //          */

    //         text-transform: uppercase;
    //         font-size: 24px;
    //         line-height: 30px;
    //         color: white;
    //         letter-spacing: 1px;

    //     }

    // }

    // /**
    //  * .image-container.
    //  */

    // .image-container {

    //     /**
    //      * 
    //      */

    //     @include position-flood-fill;
  
    //     /**
    //      * 
    //      */

    //     @include image-container();

    //     /**
    //      * img.
    //      */

    //     img {

    //         /**
    //          * Display.
    //          */

    //         display: none;

    //     }

    // }

    /**
     * .common-panel-herotypea-container.
     */

    .common-panel-herotypea-container {

        /**
         * Position.
         */

        position: relative;

        /**
         * Sizing.
         */

        overflow: hidden;

        /**
         * Sizing.
         */

        // height: calc(100vh - $nav-bar-height) !important;
        width: 100%;
        max-width: 100%;
        height: 100%;


        /**
         * Padding.
         */

        padding: 0;

        /**
         * .row.
         */

        .row {

            /**
             * Sizing.
             */

            height: 100%;

        }

        /**
         * &::before.
         */

        &::before {

            /**
             * Content.
             */

            content: '';

            /**
             * 
             */

            position: absolute;
            top: 0;

            /**
             * Sizing.
             */

            height: 30px;
            width: 100%;

            /**
             * Background.
             */

            background-color: $brand-one;

            /**
             * Z-index.
             */

            z-index: 10;

        }

        /**
         * &.with-circle.
         */

        &.with-circle {

            /**
             * .heading-wrapper.
             */

            .heading-wrapper {

                /**
                 * h1.
                 */

                h1 {

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Font related.
                     */

                    font-size: 100px;
                    line-height: 110px;
                    letter-spacing: 6px;
                    white-space: nowrap;

                    /**
                     * Z-index.
                     */

                    z-index: 2;

                    /**
                     * @include.
                     */
        
                    @include breakpoint($b-df-tablet) {
        
                        /**
                         * Font
                         */
        
                        font-size: 70px;
                        line-height: 80px;
        
                    }
                    
                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Font
                         */

                        font-size: 50px;
                        line-height: 65px;

                    }

                    /**
                     * span.
                     */

                    span {

                        /**
                         * Position.
                         */

                        position: relative;

                        /**
                         * Z-index.
                         */

                        z-index: 2;

                    }

                    
                }

                /**
                 * &::after.
                 */
            
                &::after {

                    /**
                     * Content.
                     */

                    content: '';

                    /**
                     * Background.
                     */

                    background-color: rgba( $brand-one, 0.9 );

                    /**
                     * Sizing.
                     */

                    width: 120%;

                    /**
                     * Padding.
                     */

                    padding-top: 120%;

                    // padding-bottom: 100%;

                    /**
                     * Position.
                     */

                    position: absolute;
                    top: 50%;
                    left: 50%;

                    /**
                     * Transform.
                     */

                    transform: translateX(-50%) translateY(-50%);

                    /**
                     * Border.
                     */

                    border-radius: 100%;

                    /**
                     * Z-index.
                     */

                    z-index: 0;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Sizing.
                         */

                        width: 120%;

                        /**
                         * Padding.
                         */

                        padding-top: 120%;

                    }

                    /**
                     * @include.
                     */

                     @include breakpoint($bs-extra-large) {

                        /**
                         * Sizing.
                         */

                        width: 1100px;

                        /**
                         * Padding.
                         */

                        padding-top: 1100px;

                    }
                    

                }
                    

            }

        }

        /**
         * .heading-wrapper.
         */

        .heading-wrapper {

            /**
             * Sizing.
             */

            width: 70%;

            /**
             * @include.
             */

            @include breakpoint($bs-extra-small) {

                /**
                 * Sizing.
                 */

                width: 100%;

                /**
                 * Padding.
                 */
            
                padding-left: 20px;
                padding-right: 20px;

            }

            /**
             * Position.
             */

            position: absolute;
            bottom: 0;
            left: 50%;

            /**
             * Transform.
             */

            transform: translateX(-50%);

            /**
             * Padding.
             */

            padding-bottom: 20px;

            /**
             * h1.
             */

            h1 {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * Font related.
                 */

                color: white;
                font-weight: 400;
                font-size: 60px;
                line-height: 70px;
                letter-spacing: 6px;

                /**
                 * Z-index.
                 */

                z-index: 2;

                /**
                 * Padding.
                 */

                padding-bottom: 0px;

                /**
                 * @include.
                 */
    
                @include breakpoint($bs-extra-small) {
    
                    /**
                     * Font
                     */
    
                    font-size: 30px;
                    line-height: 38px;
    
                }

                /**
                 * @include.
                 */
    
                @include breakpoint($b-df-tablet) {
    
                    /**
                     * Font.
                     */
    
                    font-size: 50px;
                    line-height: 60px;
    
                }

                /**
                 * @include.
                 */

                @include breakpoint($bs-extra-small) {

                    /**
                     * Font
                     */

                    font-size: 30px;
                    line-height: 40px;
                    letter-spacing: 3px;

                }

            }

            /**
             * Font related.
             */

            text-align: center;

            /**
             * img, svg.
             */

            img, svg {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * Sizing.
                 */

                width: 60px;

                /**
                 * Z-index.
                 */

                z-index: 2;

            }

        }

    }

}
