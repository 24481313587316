/**
 * .popup-wrapper.
 */

.popup-wrapper {

    /**
     * Position.
     */

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    /**
     * Sizing.
     */

    width: 100%;
    // height: calc(100vh - $nav-bar-height) !important;
    height: calc(100dvh) !important;

    /**
     * Overflow.
     */

    overflow-y: scroll;

    /**
     * Z-index.
     */

    z-index: 800;

    /**
     * Pointer.
     */

    pointer-events: none;

    /**
     * Opacity.
     */

    opacity: 0;

    /**
     * Transition.
     */

    transition: opacity 0.2s ease;

    /**
     * &.show.
     */

    &.show {

        /**
         * Opacity.
         */

        opacity: 1;

        /**
         * Pointer.
         */

        pointer-events: all;

    }

    /**
     * .popup-content.
     */

    .popup-content {

        /**
         * Background.
         */

        background-color: white;

    }

    /**
     * .popup-footer.
     */

    .popup-footer {

        /**
         * Sizing.
         */

        height: $nav-bar-height;
        width: 100%;

        /**
         * Position.
         */
    
        position: fixed;
        bottom: 0;
        left: 0;

        /**
         * Z-index.
         */

        z-index: 801;

        /**
         * Background.
         */

        background-color: $brand-two;

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        align-items: center;
        justify-content: flex-end;

        /**
         * a.back. 
         */

        a.back {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */
        
            align-items: center;

            /**
             * Background.
             */

            background-color: white;

            /**
             * Font related.
             */

            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: bold;
            font-size: 14px;
            color: $brand-two;
            text-decoration: none;

            /**
             * Padding.
             */

            padding: 5px 30px 5px 30px;

            /**
             * Margin.
             */

            margin-right: 25px;

            /**
             * Border.
             */

            border-radius: 30px;

            /**
             * svg.
             */

            svg {

                /**
                 * Sizing.
                 */

                height: 35px;

                /**
                 * Margin.
                 */

                margin-right: 15px;

                /**
                 * Transform.
                 */

                transform: translateY(-1px);

                /**
                 * path.
                 */

                path {

                    /**
                     * Fill.
                     */

                    fill: $brand-two;

                }

            }

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * Background.
                 */

                background-color: $brand-one;

            }

        }

    }

}

/**
 * .popup-wrapper.house-popup.
 */

.popup-wrapper.house-popup {

    /**
     * 
     */

}

/**
 * .common-panel-pinewoodgreensiteplan-wrapper.
 */

.common-panel-pinewoodgreensiteplan-wrapper {

    /**
     * .image-container.
     */

    .image-container {

        /**
         * Sizing.
         */

        height: 1000px;
        width: 100%;

        /**
         * img.
         */

        img {

            display: none;

        }

    }

    /**
     * .common-panel-pinewoodgreensiteplan-container.
     */

    .common-panel-pinewoodgreensiteplan-container {

        /**
         * Background.
         */

        // background-color: palegreen;

        /**
         * Margin.
         */

        margin-bottom: 30px;

        /**
         * .siteplan.
         */

        .siteplan {

            /**
             * Sizing.
             */

            width: 100%;

        }

        /**
         * .row-heading.
         */

        .row-heading {

            /**
             * Font related.
             */

            text-align: center;

            /**
             * Padding.
             */

            padding-bottom: 20px;

        }

        /**
         * .row-key.
         */

        .row-key {

            /**
             * Flex.
             */

            justify-content: center;

        }

        /**
         * .row-disclaimer.
         */

        .row-disclaimer {

            /**
             * Padding.
             */

            padding-bottom: 100px;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Padding.
                 */

                padding-bottom: 20px;

            }

            /**
             * p.
             */

            p {

                /**
                 * Font related.
                 */

                font-size: 14px;
                line-height: 20px;

            }

        }

        

        /**
         * .key.
         */

        .key {

            /**
             * Margin.
             */

            margin-top: 60px;
            margin-bottom: 30px;

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */
    
            flex-wrap: wrap;
            justify-content: center;

            /**
             * li.
             */

            li {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Margin.
                 */

                margin-bottom: 30px;

                /**
                 * Sizing.
                 */

                width: 30%;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Sizing.
                     */

                    width: 50%;

                    /**
                     * Margin.
                     */

                    margin-right: 0;

                }

                /**
                 * @include.
                 */

                @include breakpoint($bs-extra-small) {

                    /**
                     * Sizing.
                     */

                    width: 50%;

                }

                /**
                 * Sizing.
                 */

                // width: 33.3%;

                /**
                 * .color-indicator.
                 */

                .color-indicator {

                    /**
                     * Display.
                     */

                    display: inline-block;

                    /**
                     * Sizing.
                     */

                    width: 30px;
                    height: 30px;

                    /**
                     * Margin.
                     */

                    margin-right: 10px;

                }

                /**
                 * .text.
                 */

                .text {

                    /**
                     * Display.
                     */

                    display: inline-block;

                    /**
                     * Font related.
                     */

                    font-size: 20px;
                    line-height: 34px;

                }

                /**
                 * 
                 */

                .beds, .subtitle {

                    /**
                     * Display.
                     */

                    display: block;

                    /**
                     * Font related.
                     */

                    font-size: 14px;
                    line-height: 17px;
                    text-transform: uppercase;

                    // width: 100%;

                }

            }

        }

    }

}
