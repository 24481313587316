/**
 * .common-panel-blocksetb-wrapper.
 */

.common-panel-blocksetb-wrapper {

    /**
     * Overlfow.
     */

    overflow: hidden;

    /**
     * .common-panel-blocksetb-container.
     */

    .common-panel-blocksetb-container {

        /**
         * Background.
         */

        // background-color: palegoldenrod;

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Padding.
             */

            padding-top: 60px;

        }

        /**
         * .col-grid.
         */

        .col-grid {

            /**
             * Display.
             */
        
            display: grid;

            /**
             * Grid.
             */

            grid-template-columns: 4fr 8fr;
            grid-template-rows: repeat( 50, 2fr );

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Grid.
                 */

                grid-template-columns: auto;
                grid-template-rows: auto;
                grid-template-areas: 
                'a'
                'b'
                'c'
                'd';

                /**
                 * Sizing.
                 */

                min-height: auto;

                /**
                 * Padding.
                 */

                padding-left: 0;
                padding-right: 0;

            }

            /**
             * Sizing.
             */

            min-height: 1600px;
            width: 100%;

            /**
             * .intro.
             */

            .intro {

                /**
                 * Background.
                 */

                // background-color: rgba( cyan, 0.6 );

                /**
                 * Grid.
                 */

                grid-row: auto / span 14;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Grid.
                     */

                    grid-row: auto;
                    grid-column: auto;
                    grid-area: a;

                    /**
                     * Margin.
                     */

                    margin-bottom: 60px;

                    /**
                     * Font related.
                     */

                    text-align: center;

                    /**
                     * Padding.
                     */

                    padding-left: 20px;
                    padding-right: 20px;

                }

                /**
                 * Margin.
                 */

                margin-bottom: 10px;
                margin-right: 10px;

                /**
                 * @include.
                 */

                @include breakpoint($b-mobile-only) {

                    /**
                     * Padding.
                     */

                    padding-left: 15px;
                    padding-right: 15px;
                    
                }

            }

            /**
             * img.
             */

            img {

                /**
                 * Display.
                 */

                display: none;

            }

            /**
             * .heading-wrapper.
             */

            .heading-wrapper {

                /**
                 * Background.
                 */

                background-color: $brand-one;

                /**
                 * Padding.
                 */

                padding-top: 30px;
                padding-bottom: 20px;

                /**
                 * Margin.
                 */

                margin-bottom: 20px;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Margin.
                     */

                    margin-bottom: 0;
                    
                }

                /**
                 * p.
                 */

                p {

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Font related.
                     */

                    font-family: AdventuresUnlimitedScriptRegular;
                    font-size: 80px;
                    line-height: 50px;
                    color: white;

                    /**
                     * Z-index.
                     */

                    z-index: 1;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-tablet) {

                        /**
                         * Font related.
                         */

                        font-size: 58px;
                        line-height: 40px;
                        
                    }

                }

                /**
                 * &.text-inset-left.
                 */

                &.text-inset-left {

                    /**
                     * Padding.
                     */

                    padding-left: $heading-wrapper-left-padding;

                }

                /**
                 * &.spacer.
                 */

                &.spacer {

                    /**
                     * Background.
                     */

                    background-color: transparent;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Display.
                         */

                        display: none;
                        
                    }

                }
                
            }
            
            /**
             * .group1.
             */

            .group1 {

                /**
                 * Background.
                 */

                // background-color: rgba( crimson, 0.6 );

                /**
                 * Grid.
                 */

                grid-row: auto / span 19;
                grid-column: 1 / span 1;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Grid.
                     */

                    grid-row: auto;
                    grid-column: auto;
                    grid-area: c;

                    /**
                     * Margin.
                     */

                    margin-right: 0;
                    margin-left: 0;
                    margin-top: 0;
                    margin-bottom: 60px;

                }

                /**
                 * Margin.
                 */

                margin-top: 10px;
                margin-bottom: 10px;
                margin-right: 10px;
                
                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;
                align-items: flex-end;

                /**
                 * .image-container.
                 */

                .image-container {

                    /**
                     * Flex.
                     */

                    flex-grow: 1;

                    /**
                     * Sizing.
                     */

                    width: 180%;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Sizing.
                         */

                        width: 100%;
                        height: 350px;
                        
                    }

                }

                /**
                 * .heading-wrapper.
                 */

                .heading-wrapper {

                    /**
                     * Sizing.
                     */

                    width: 100%;

                    /**
                     * Padding.
                     */

                    padding-top: 30px;

                    /**
                     * @include.
                     */

                    @include background-extension-left();

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Padding.
                         */

                        padding-left: 30px;

                        /**
                         * &::after.
                         */

                        &::after {

                            /**
                             * Content.
                             */

                            content: none;

                        }

                    }

                    /**
                     * p.
                     */

                    p {

                        /**
                         * @include.
                         */

                        @include breakpoint($b-df-mobile-landscape) {

                            /**
                             * Sizing.
                             */

                            width: 70%;

                        }

                    }

                }

                /**
                 * img.
                 */

                img {

                    /**
                     * Display.
                     */

                    display: none;

                }

                /**
                 * img.graphic.
                 */

                img.graphic {

                    /**
                     * Display.
                     */

                    display: block;
                    
                    /**
                     * Posiiton.
                     */

                    position: absolute;
                    top: 20px;
                    left: 0;

                    /**
                     * Transform.
                     */

                    transform: translateY(-100%);

                    /**
                     * Z-index.
                     */

                    z-index: 1;

                    /**
                     * Sizing.
                     */

                    width: 160px;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Sizing.
                         */

                        left: 65%;

                        /**
                         * Transform.
                         */

                        transform: translateY(-50%);

                    }

                }

            }

            /**
             * .group2.
             */

            .group2 {

                /**
                 * Background.
                 */

                // background-color: rgba( purple, 0.6 );

                /**
                 * Grid.
                 */

                grid-row: 1 / 27;
                grid-column: 2 / 3;
                
                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Grid.
                     */

                    grid-row: auto;
                    grid-column: auto;
                    grid-area: b;

                    /**
                     * Margin.
                     */

                    margin: 0 0 60px 0;

                }

                /**
                 * Margin.
                 */

                margin-bottom: 10px;
                margin-left: 10px;

                /**
                 * Background.
                 */

                // background-color: orange;

                /**
                 * .row.
                 */

                .row {

                    /**
                     * Sizing.
                     */

                    width: 100%;
                    height: 100%;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Sizing.
                         */

                        width: auto;

                        /**
                         * Margin.
                         */

                        margin: 0;

                    }

                }

                /**
                 * .col-inset.
                 */

                .col-inset {

                    /**
                     * Sizing.
                     */

                    width: 100%;
                    height: 100%;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Padding.
                         */

                        padding-left: 0;
                        padding-right: 0;

                    }

                    /**
                     * Display.
                     */
    
                    display: flex;
    
                    /**
                     * Flex.
                     */
    
                    flex-direction: column;
                    align-items: flex-start;
    
                    /**
                     * .heading-wrapper.
                     */

                    .heading-wrapper {

                        /**
                         * Sizing.
                         */

                        width: 100%;

                        /**
                         * Position.
                         */

                        position: relative;

                    }

                    /**
                     * .image-container.
                     */
    
                    .image-container {
    
                        /**
                         * Flex.
                         */
    
                        flex-grow: 1;
    
                        /**
                         * Sizing.
                         */

                        width: 100%;
        
                        /**
                         * @include.
                         */

                        @include breakpoint($b-df-mobile-landscape) {

                            /**
                             * Sizing.
                             */

                            width: 100%;
                            height: 350px;
                            
                        }

                    }
    
                    /**
                     * img.graphic.
                     */

                    img.graphic {

                        /**
                         * Display.
                         */

                        display: block;
                        
                        /**
                         * Posiiton.
                         */

                        position: absolute;
                        bottom: 10px;
                        right: 30px;

                        /**
                         * Z-index.
                         */

                        z-index: 1;

                        /**
                         * Sizing.
                         */

                        width: 130px;

                        /**
                         * @include.
                         */

                        @include breakpoint($b-df-mobile-landscape) {

                            /**
                             * Sizing.
                             */

                            width: 100px;
                            
                        }

                    }

                }

            }

            /**
             * .group3.
             */

            .group3 {

                /**
                 * Background.
                 */

                // background-color: rgba( green, 0.6 );

                /**
                 * Grid.
                 */

                 
                grid-row: 27 / span 50;
                grid-column: 2 / span 1;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Grid.
                     */

                    grid-row: auto;
                    grid-column: auto;
                    grid-area: d;

                    /**
                     * Margin.
                     */

                    margin: 0;

                }

                /**
                 * Margin.
                 */

                margin-top: 10px;
                margin-left: 10px;

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;
                align-items: flex-start;

                /**
                 * .image-container.
                 */

                .image-container {

                    /**
                     * Sizing.
                     */

                    width: 160%;

                    /**
                     * Felx.
                     */

                    flex-grow: 1;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Sizing.
                         */

                        width: 100%;
                        height: 350px;
                        
                    }

                }

                /**
                 * .heading-wrapper.
                 */

                .heading-wrapper {

                    /**
                     * @include.
                     */

                    @include background-extension-right();

                    /**
                     * Sizing.
                     */

                    width: 100%;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * &::after.
                         */

                        &::after {

                            /**
                             * Content.
                             */

                            content: none;

                        }

                    }

                }

                /**
                 * img.
                 */

                img {

                    /**
                     * Display.
                     */

                    display: none;

                }

                /**
                 * img.graphic.
                 */

                img.graphic {

                    /**
                     * Display.
                     */

                    display: block;
                    
                    /**
                     * Posiiton.
                     */

                    position: absolute;
                    bottom: 0;
                    left: 75%;

                    /**
                     * Z-index.
                     */

                    z-index: 1;

                    /**
                     * Sizing.
                     */

                    width: 150px;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-mobile-landscape) {

                        /**
                         * Sizing.
                         */

                        left: 65%;

                    }

                }

            }

        }

    }

}
