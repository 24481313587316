/**
 * .nav-bar-wrapper.
 */

.nav-bar-wrapper {

    /**
     * Background.
     */

    background-color: $brand-two;

    /**
     * Position.
     */

    position: fixed;
    bottom: 0;

    /**
     * Sizing.
     */

    width: 100%;

    /**
     * Z-index.
     */

    z-index: 600;

    /**
     * &.strip-above.
     */

    &.strip-above {
        
        /**
         * &::after.
         */

        &::after {

            /**
             * Content.
             */

            content: '';

            /**
             * 
             */

            position: absolute;
            top: 0;

            /**
             * Sizing.
             */

            height: 30px;
            width: 100%;

            /**
             * Transform.
             */

            transform: translateY(-100%);

            /**
             * Background.
             */

            background-color: $brand-one;

        }

    }
    
    /**
     * .col-left, .col-right.
     */

    .col-left, .col-right {

        /**
         * Sizing.
         */

        height: $nav-bar-height;

    }

    /**
     * .col-left.
     */

    .col-left {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        align-items: center;

        /**
         * Padding.
         */

        padding-left: 30px;

        /**
         * @include.
         */

        @include breakpoint($bs-extra-small) {

            /**
             * Padding.
             */

            padding-right: 0;
            padding-left: 20px;

        }

        /**
         * .pagination.
         */

        .pagination {

            /**
             * Margin.
             */

            margin-left: 15px;
            margin-bottom: 0;

            /**
             * Font related.
             */

            color: $brand-three;
            font-size: 24px;
            line-height: 30px;
            font-weight: bold;

            /**
             * @include.
             */

            @include breakpoint($bs-extra-small) {

                /**
                 * Font related.
                 */

                font-size: 18px;

            }

            /**
             * span.
             */

            span {
                
                /**
                 * Margin.
                 */
                
                margin-left: 5px;

            }

        }

    }

    /**
     * .col-right.
     */

    .col-right {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        justify-content: flex-end;
        align-items: center;

        /**
         * Padding.
         */

        padding-right: 30px;

        /**
         * @include.
         */

        @include breakpoint($bs-extra-small) {

            /**
             * Padding.
             */

            padding-right: 20px;

        }

        /**
         * ul.
         */

        ul {

            /**
             * li.
             */

            li {

                /**
                 * Display.
                 */

                display: inline-block;

                /**
                 * Margin.
                 */

                margin-left: 5px;

                /**
                 * a.
                 */

                a {

                    /**
                     * Display.
                     */

                    display: block;

                    /**
                     * Padding.
                     */
    
                    padding: 8px;
    
                    /**
                     * Background.
                     */
    
                    background-color: white;
    
                    /**
                     * Border.
                     */
    
                    border-radius: 100%;
    
                    /**
                     * svg.
                     */
    
                    svg {
    
                        /**
                         * Sizing.
                         */
    
                        height: 40px;
                        width: 40px;

                        /**
                         * @include.
                         */

                        @include breakpoint($bs-extra-small) {

                            /**
                             * Sizing.
                             */

                            height: 30px;
                            width: 30px;

                        }

                        /**
                         * path.
                         */

                        path {

                            /**
                             * Fill.
                             */

                            fill: $brand-two;

                        }
    
                    }
    
                    /**
                     * span.
                     */
    
                    span {
    
                        /**
                         * Display.
                         */
    
                        display: none;
    
                    }

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * 
                         */

                        background-color: $brand-one;

                    }

                }

            }

        }

    }

}
