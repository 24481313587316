/**
 * 
 */

$map-height: 700px;

/**
 * .common-panel-interactivemap-wrapper.
 */

.common-panel-interactivemap-wrapper {

    /**
     * .common-panel-interactivemap-container.
     */

    .common-panel-interactivemap-container {

        /**
         * Padding.
         */

        padding-top: 60px;
        padding-bottom: 60px;

        /**
         * .interactive-map-wrapper.
         */

        .interactive-map-wrapper {

            /**
             * Position.
             */

            position: relative;

            /**
             * Sizing.
             */

            height: $map-height;

            /**
             * &::after.
             */

            &::after {

                /**
                 * Content.
                 */

                content: '';

                /**
                 * Border.
                 */

                border: 1px solid $brand-four;

                /**
                 * 
                 */

                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                /**
                 * Z-index.
                 */

                z-index: 400;

                /**
                 * Pointer.
                 */

                pointer-events: none;

            }

            /**
             * .interaction-message-wrapper.
             */

            .interaction-message-wrapper {

                /**
                 * Padding.
                 */

                padding: 40px 30px 30px 30px;

                /**
                 * @include.
                 */

                @include position-transform-center;

                /**
                 * Pointer.
                 */

                pointer-events: none;

                /**
                 * Z-index.
                 */

                z-index: 500;

                /**
                 * Background.
                 */
            
                background-color: white;

                /**
                 * Sizing.
                 */

                width: 400px;
                
                /**
                 * Border.
                 */

                border-radius: 20px;

                /**
                 * Font related.
                 */

                text-align: center;

                /**
                 * Transition.
                 */

                transition: opacity 0.2s ease;

                /**
                 * @include.
                 */

                @include breakpoint($bs-extra-small) {

                    /**
                     * Sizing.
                     */

                    max-width: 90%;

                }

                /**
                 * .title.
                 */

                .title {

                    /**
                     * Font related.
                     */

                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-size: 20px;
                    line-height: 24px;

                    /**
                     * Margin.
                     */

                    margin-bottom: 12px;

                }

                /**
                 * p.instructions.
                 */

                p.instructions {

                    /**
                     * Font related.
                     */

                    line-height: 22px;

                }

                /**
                 * &.hidden.
                 */

                &.hidden {

                    /**
                     * Opacity.
                     */

                    opacity: 0;

                }

            }

        }

        /**
         * .interactive-map-container.
         */

        .interactive-map-container {

            /**
             * Sizing.
             */

            height: $map-height;
            width: 100%;

            /**
             * Background.
             */

            // background-color: rgba( black, 0.1 );

            /**
             * 
             */

            img.map {

                max-width: 100%;

            }

            /**
             * #interactive-map.
             */

            #interactive-map {

                /**
                 * Sizing.
                 */

                height: $map-height;

            }

            /**
             * .leaflet-container.
             */

            .leaflet-container {

                /**
                 * 
                 */

                background-color: rgba( black, 0.05 );

            }

            /**
             * .leaflet-control-attribution.
             */

            .leaflet-control-attribution {

                /**
                 * Display.
                 */

                display: none;

            }

            /**
             * .leaflet-top.
             */

            .leaflet-top {

                /**
                 * Z-index.
                 */

                z-index: 400;

            }

            /**
             * a.leaflet-popup-close-button.
             */

            a.leaflet-popup-close-button {

                /**
                 * Font related.
                 */

                color: black;

            }

            /**
             * .leaflet-popup.
             */

            .leaflet-popup {

                /**
                 * 
                 */

                &.color-BCC05A .leaflet-popup-tip { background-color: #BCC05A; }
                &.color-F08667 .leaflet-popup-tip { background-color: #F08667; }
                &.color-F5A14C .leaflet-popup-tip { background-color: #F5A14C; }
                &.color-CD7C9F .leaflet-popup-tip { background-color: #CD7C9F; }
                &.color-F5A996 .leaflet-popup-tip { background-color: #F5A996; }
                &.color-29C0B9 .leaflet-popup-tip { background-color: #29C0B9; }
                &.color-CB56AA .leaflet-popup-tip { background-color: #CB56AA; }
                &.color-6BAFBE .leaflet-popup-tip { background-color: #6BAFBE; }

                /**
                 * &.popup-position-below.
                 */

                &.popup-position-below {

                    /**
                     * Position.
                     */

                    bottom: auto !important;
                    top: auto !important;
                
                    /**
                     * Margin.
                     */

                    margin-top: 50px;

                    /**
                     * .leaflet-popup-tip-container.
                     */

                    .leaflet-popup-tip-container {

                        /**
                         * Position.
                         */

                        top: -19px;

                        /**
                         * Margin.
                         */

                        margin-left: -19px;

                        /**
                         * Overflow.
                         */

                        overflow: hidden;

                        /**
                         * Pointer.
                         */

                        pointer-events: none;

                        /**
                         * Transform.
                         */

                        transform: rotate(180deg);

                    }

                }

            }

            /**
             * .leaflet-control.
             */

            .leaflet-control {

                /**
                 * Margin.
                 */

                margin: 20px;

                /**
                 * Border.
                 */

                border-radius: 5px;
                border: 1px solid rgba( black, 0.1 );

                /**
                 * Overflow.
                 */

                overflow: hidden;

                /**
                 * .leaflet-control-zoom-in.
                 */

                .leaflet-control-zoom-in {

                    /**
                     * Display.
                     */

                    display: flex;

                    /**
                     * Flex.
                     */

                    justify-content: center;
                    align-items: center;

                    /**
                     * Padding.
                     */

                    padding: 25px;

                }

                /**
                 * .leaflet-control-zoom-out.
                 */

                .leaflet-control-zoom-out {

                    /**
                     * Display.
                     */

                    display: flex;

                    /**
                     * Flex.
                     */

                    justify-content: center;
                    align-items: center;

                    /**
                     * Padding.
                     */

                    padding: 25px;

                }

            }

            /**
             * .leaflet-popup-content.
             */

            .leaflet-popup-content {

                /**
                 * Margin.
                 */

                margin: 0;

            }

            /**
             * .leaflet-popup-content-wrapper.
             */

            .leaflet-popup-content-wrapper {
                
                /**
                 * Background.
                 */

                // background-color: $brand-two;

                /**
                 * Overflow.
                 */

                overflow: hidden;

                /**
                 * Font related.
                 */

                color: white;
                text-align: center;

                /**
                 * Padding.
                 */

                padding: 0;

                /**
                 * Border.
                 */

                border-radius: 5px;

                /**
                 * Shadow.
                 */

                box-shadow: 0 3px 14px rgba(0, 0, 0, 0.2);

                /**
                 * p.
                 */

                p {

                    /**
                     * Margin.
                     */

                    margin: 0;

                }

                /**
                 * .text-inset.
                 */

                .text-inset {

                    /**
                     * Padding.
                     */

                    padding: 20px 20px 20px 20px;

                }

                /**
                 * .image-container.
                 */

                .image-container {

                    /**
                     * Sizing.
                     */

                    width: 360px;
                    height: 180px;

                    /**
                     * img
                     */

                    img {

                        /**
                         * Display.
                         */

                        display: none

                    }

                }

                /**
                 * .title.
                 */

                .title {

                    /**
                     * Font related.
                     */

                    text-transform: uppercase;
                    letter-spacing: 4px;
                    font-size: 16px;
                    color: black;

                    /**
                     * Margin.
                     */

                    margin-bottom: 0;

                }

            }

            /**
             * .leaflet-popup-tip.
             */

            .leaflet-popup-tip {

                /**
                 * Background.
                 */

                background-color: $brand-two;

            }

        }

    }

}
