/**
 * .common-panel-berkeleygrouplogos-wrapper.
 */

.common-panel-berkeleygrouplogos-wrapper {

    /**
     * .common-panel-berkeleygrouplogos-container.
     */

    .common-panel-berkeleygrouplogos-container {

        /**
         * Font related.
         */

        // background-color: red;

        /**
         * .row-main.
         */

        .row-main {

            /**
             * Position.
             */

            position: relative;

            /**
             * 
             */
        
            // background-color: palegreen;

            /**
             * Padding.
             */
        
            padding-bottom: 28px;

            /**
             * Margin.
             */

            margin-bottom: 8px;

            /**
             * > div.
             */

            > div {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                align-items: center;
                justify-content: center;

            }

            /**
             * .col-aux-logo.
             */

            .col-aux-logo {

                /**
                 * 
                 */

                justify-content: flex-start;

            }

            /**
             * .main-logo.
             */

            .main-logo {

                /**
                 * Sizing.
                 */

                height: 90px;

            }

            /**
             * .aux-logo.
             */

            .aux-logo {

                /**
                 * Sizing.
                 */

                height: 80px;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Display.
                     */

                    display: none;

                }

            }

            /**
             * &::after.
             */

            &::after {

                /**
                 * Content.
                 */

                content: '';

                /**
                 * Position.
                 */

                position: absolute;
                bottom: 0;
                left: 15px;
                right: 15px;

                /**
                 * Sizing.
                 */

                height: 1px;

                /**
                 * Background.
                 */

                background-color: rgba( black, 0.2 );

            }

        }

        /**
         * .col-main-logo.
         */

        .col-main-logo {

            /**
             * Font related.
             */

            text-align: center;

        }

        /**
         * .row-note.
         */

        .row-note {

            /**
             * Margin.
             */

            margin-bottom: 5px;

            /**
             * p.
             */

            p {
                /**
                 * Font related.
                 */
                
                text-align: center;
                color: rgba( black, 0.5 );
                font-size: 19px;

            }

        }
        
        /**
         * .row-logos.
         */

        .row-logos {

            /**
             * .logos.
             */

            .logos {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                justify-content: space-between;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-tablet) {

                    /**
                     * Margin.
                     */

                    margin-left: 30px;
                    margin-right: 30px;

                    /**
                     * Flex.
                     */

                    flex-wrap: wrap;

                    /**
                     * li.
                     */

                    li {

                        /**
                         * Sizing.
                         */

                        width: 40%;

                        /**
                         * Margin.
                         */

                        margin-top: 20px;
                        margin-bottom: 10px;

                        /**
                         * Font.
                         */

                        text-align: left;

                        /**
                         * ing.
                         */

                        img {

                            /**
                             * Sizing.
                             */

                            height: 60px;

                        }

                    }

                }

                /**
                 * li.
                 */

                li {                   

                    /**
                     * img.
                     */

                    img {

                        /**
                         * Sizing.
                         */

                        min-height: 45px;

                        /**
                         * @include.
                         */

                        @include breakpoint($bs-extra-small) {

                            /**
                             * Sizing.
                             */

                            max-width: 100%;

                        }

                    }

                }

            }

        }

    }

}
